import React, { useState, useEffect } from "react"; 
import "./LearningHub.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import ReadMoreArrow from "../../assets/images/read-more-arrow.svg";
import { useSelector, useDispatch } from "react-redux";
import { fetchLearningHubData } from "../../services/LearningHub/LearningHub";
import Pagination from "../../components/Pagination/Pagination"; // Import Pagination component
import singleArrowRight from "../../assets/images/singleArrowRight.svg";
import doubleArrowRight from "../../assets/images/doubleArrowRight.svg";

const LearningHub = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Redux state
  const { learningHubData, loading, error } = useSelector((state) => state.learninghub);

  // Local state for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; 

  useEffect(() => {
    dispatch(fetchLearningHubData({page:currentPage}));
  }, [dispatch]);

  useEffect(() => {
    if (learningHubData) {
      setCurrentPage(learningHubData.currentPage || 1);
    }
  }, [learningHubData]);

  // Handle pagination
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the total pages
  const totalPages = learningHubData?.totalItems;
console.log(totalPages);

  // Get current page items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = learningHubData?.data?.slice(indexOfFirstItem, indexOfLastItem) || [];

  return (
    <div className="main-learninghub-container">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <>
          <div>
            {currentItems.map((data) => (
              <div
                key={data._id}
                className="learninghub-container"
                onClick={() => navigate(`/articleview/${data._id}`)}
              >
                <div className="learninghub-image-container">
                  <img
                    className="learninghub-image"
                    src={data.image || 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
                    alt={data.title}
                    onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
                  />
                </div>
                <div className="learninghub-name-date-container">
                  <div>{data.author}</div>
                  <div>{new Date(data.publishedAt).toLocaleDateString()}</div>
                </div>
                <h5 className="learninghub-title-container">{data.title}</h5>
                <p>{data.content.substring(0, 100)}...</p>
                <CustomButton
                  label={
                    <span className="get-button-aliment">
                      Read More{" "}
                      <img src={ReadMoreArrow} alt="arrow" className="get-button-icon" />
                    </span>
                  }
                  type="readmore-button"
                />
              </div>
            ))}
          </div>

          {/* Pagination Component */}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            paginate={paginate}
            singleArrowRight={singleArrowRight}
            doubleArrowRight={doubleArrowRight}
          />
        </>
      )}
    </div>
  );
};

export default LearningHub;
