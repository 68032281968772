import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./UserManagement.css";

import TitlePath from "../../../components/TitlePath/TitlePath";
import DashboardHeading from "../../../components/DashboardHeading/DashboardHeading";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { fetchAllUsers } from "../../../services/admin/adminService";

function AdminManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state
  const { users, loading, error } = useSelector((state) => state.admin); // Fetch users from Redux state
  const admin = users || []; // Fallback to empty array if users is undefined

  const [activeTab, setActiveTab] = useState("All");

  // Define table columns
  const columns = [
    { key: "id", label: "Admin ID", isClickable: true },
    { key: "name", label: "Name", isClickable: true },
    { key: "email", label: "Email", isClickable: true },
    { key: "role", label: "Role", isClickable: true },
    { key: "joinDate", label: "Join Date", isClickable: true },
    {
      key: "status",
      label: "Status",
      type: "status",
      sortable: true,
      isClickable: true,
    },
  ];

  // Fetch data when the component mounts
  useEffect(() => {
    dispatch(fetchAllUsers());
  }, [dispatch]);

  // Map API data to match table structure
  const mappedData = admin.map((user) => ({
    id: user.index,
    _id: user.id,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    role: user.userRole,
    joinDate: user.createdAt || "N/A", // Use a valid field for join date
    status: user.accountStatus,
  }));

  // Calculate counts for each tab
  const allCount = mappedData.length;
  const applicantCount = mappedData.filter(
    (admin) => admin.role === "applicant",
  ).length;
  const referrerCount = mappedData.filter(
    (admin) => admin.role === "referrer",
  ).length;

  const tabs = [
    { label: "All", count: allCount },
    { label: "Applicant", count: applicantCount },
    { label: "Referrer", count: referrerCount },
  ];

  // Filter admins based on active tab
  const filteredData =
    activeTab === "All"
      ? mappedData
      : mappedData.filter((admin) => admin.role === activeTab.toLowerCase());

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };
  const handleViewClick = (id) => {
    navigate(`/admin_dashboard/user_management/${id}`);
  };

  const titlePath = {
    exactPath: (
      <span style={{ cursor: "pointer" }} onClick={handleBack}>
        user_management 
      </span>
    ),
    paths: [{ label: "Dashboard > ", onClick: handleBack }],
  };

  const headingDes = {
    heading: "User Management",
    des: "Effortlessly manage admins and their roles with organized details and quick actions.",
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <div className="main-dashboard-container">
        <div>
          <TitlePath
            exactPath={titlePath.exactPath}
            paths={titlePath.paths}
            showNotification={true}
          />
          <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
        </div>

        <div className="setting-page-container">
          <div className="dashboard-table-heading">
            <h4>Overview</h4>
          </div>

          <div className="sub-navbar">
            {tabs.map((tab) => (
              <div
                key={tab.label}
                className={`sub-navbar-item ${activeTab === tab.label ? "active" : ""}`}
                onClick={() => setActiveTab(tab.label)}
              >
                {tab.label} <span className="count">{tab.count}</span>
              </div>
            ))}
          </div>

          <div className="sub-setting-page-container blog-post-table-container">
            <CustomTable
              columns={columns}
              data={filteredData} // Pass filtered data
              showActionColumn={false}
              handleViewClick={handleViewClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminManagement;
