import React, { useEffect, useState } from "react";
import "./ChatModal.css";
import people1 from "../../../assets/images/people1.jpg";
import people2 from "../../../assets/images/people2.jpg";
import ChatContainer from "../../../containers/AdminLayout/ChatManager/ChatContainer/ChatContainer";
import { useDispatch, useSelector } from "react-redux";
import { openChat } from "../../../services/jobSeeker/jobSeekerService";

const ChatModal = ({ isOpen, onClose, requestId, jobReferralDetail, chatType = "jobReferral" }) => {
  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);

  const user = localStorage.getItem("user");
  const firstName = JSON.parse(user).user.personalInfo.firstName;
  console.log("First Name:", firstName);

  const dispatch = useDispatch();

  useEffect(() => {
    const queryParams = {
      // chatType: "jobReferral",
      chatType: chatType,  // Use the dynamic chatType prop
      requestId: requestId,
    };

    if (requestId) {
      dispatch(openChat(queryParams));
    }
  }, [dispatch, requestId, chatType]);

  const chat = useSelector((state) => state.jobSeeker.selectedChat || {});

  console.log("ChatModel ID:",requestId);
  

  if (!isOpen && !isMinimized) return null;

  const handleMinimize = () => {
    setIsMinimized(true);
    setIsMaximized(false);
  };

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
    setIsMinimized(false);
  };

  const handleRestore = () => {
    setIsMinimized(false);
    setIsMaximized(true);
  };

  return (
    <div>
      {/* Minimized Button */}
      {isMinimized && (
        <div
          className="chat-minimized"
          onClick={handleRestore}
          title="Click to expand chat"
        >
          <span>
            {jobReferralDetail?.industryDetails?.desiredCompany} -{" "}
            {jobReferralDetail?.industryDetails?.desiredPosition}
          </span>
          <span className="notification-badge">{chat?.badge}</span>
        </div>
      )}

      {/* Modal Overlay */}
      {!isMinimized && (
        <div className={`modal-overlay ${isMaximized ? "maximized" : ""}`}>
          <div
            className={`modal-drawer ${
              isMinimized ? "minimized" : isMaximized ? "maximized" : ""
            }`}
          >
            {/* Modal Header */}
            <div className="modal-header">
              <div className="modal-header-left">
                <h4>
                  {jobReferralDetail?.industryDetails?.desiredCompany} -{" "}
                  {jobReferralDetail?.industryDetails?.desiredPosition}
                </h4>
              </div>
              <div className="modal-header-right">
                <button onClick={handleMinimize} title="Minimize">
                  ➖
                </button>
                <button onClick={handleMaximize} title="Maximize">
                  {isMaximized ? "🗗" : "🗖"}
                </button>
                <button onClick={onClose} title="Close">
                  ✖
                </button>
              </div>
            </div>

            {/* Chat Container */}
            {!isMinimized && (
              <div className="modal-content">
                <ChatContainer job={chat} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatModal;
