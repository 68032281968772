import { showToastMessage } from "../../redux/actions/toastActions";
import { startLoading, stopLoading } from "../../redux/actions/spinnerActions";
import { apiRequest } from "../../utils/apiRequest";
import { fetchLearningHubDataSuccess, fetchLearningHubDataFailure,fetchLearningHubSuccess,fetchLearningHubFailure,createLearningHubSuccess,createLearningHubFailure,updateLearningHubSuccess,updateLearningHubFailure,} from "../../redux/actions/learninghubAction";

// Helper function to dispatch error messages
const handleApiError = (dispatch, error, errorMessage) => {
    console.error(errorMessage, error);
    dispatch(showToastMessage(errorMessage, "error"));
  };


  // Api Service for fetching learning hub data
export const fetchLearningHubData = (queryParams = {}) => {
    return async (dispatch) => {
      dispatch(startLoading());
  
      try {
        // Construct query string from queryParams
        const query = new URLSearchParams(queryParams).toString();
        const endpoint = `/api/learning-hub${query ? `?${query}` : ""}`;
  
        // Make the API request
        const response = await apiRequest(endpoint, {}, false, dispatch);
  
        // Handle successful response
        console.log("Fetched Learning Hub Data:", response.data);
        dispatch(fetchLearningHubDataSuccess(response));
        dispatch(showToastMessage("Learning hub data fetched successfully!", "success"));
      } catch (error) {
        // Handle error response
        console.error("Failed to fetch learning hub data:", error);
        dispatch(fetchLearningHubDataFailure(error));
        handleApiError(dispatch, error, "Failed to fetch learning hub data.");
      } finally {
        // Stop loading state
        dispatch(stopLoading());
      }
    };
  };

  export const fetchLearningHubById = (id) => {
    return async (dispatch) => {
      dispatch(startLoading());
      console.log(id);
      
      try {
        const response = await apiRequest(
          `/api/learning-hub/${id}`,
          {},
          false,
          dispatch
        );
        console.log("Learning Hub Data:", response.data);
        dispatch(fetchLearningHubSuccess(response.data));
        dispatch(showToastMessage("Learning hub content fetched successfully!", "success"));
      } catch (error) {
        console.error("Failed to fetch learning hub:", error);
        dispatch(fetchLearningHubFailure(error));
        handleApiError(dispatch, error, "Failed to fetch learning hub.");
      } finally {
        dispatch(stopLoading());
      }
    };
  };


  export const createLearningHubRequest = (formData) => {
    return async (dispatch) => {
      dispatch(startLoading());
  
      try {
        const response = await apiRequest(
          "/api/learning-hub",
          {
            method: "POST",
            body: formData,
          },
          false, // Ensure the request is not forced as JSON (FormData is not JSON)
          dispatch
        );
  
        dispatch(createLearningHubSuccess(response)); // Dispatch success action
        return { status: "success", data: response };
      } catch (error) {
        console.error("API Error:", error);
        dispatch(createLearningHubFailure(error.message)); // Dispatch failure action
        return { status: "error", error: error.message };
      } finally {
        dispatch(stopLoading()); // Ensure loading stops in all cases
      }
    };
  };
  
  
export const updateLearningHubPost = (id, updatedData) => {
  return async (dispatch) => {
    dispatch(startLoading());

    try {
      const response = await apiRequest(`/api/learning-hub/${id}`, {
        method: "PUT",
        body: updatedData,
      }, false, dispatch);

      console.log("Updated data:", response);

      dispatch(updateLearningHubSuccess(response));
      dispatch(showToastMessage("Learning hub post updated successfully!", "success"));

      return { status: "success", data: response };  // Ensure correct response structure
    } catch (error) {
      console.error("Update API Error:", error);
      dispatch(showToastMessage("Failed to update post!", "error"));
      return { status: "error", error: error.message };  // Ensure correct error handling
    } finally {
      dispatch(stopLoading());
    }
  };
};
 


  