import {
    UPDATE_LEARNING_HUB_SUCCESS,
    UPDATE_LEARNING_HUB_FAILURE,
    FETCH_LEARNING_HUB_SUCCESS,
    FETCH_LEARNING_HUB_FAILURE,
    FETCH_LEARNINGHUB_DATA_SUCCESS,
    FETCH_LEARNINGHUB_DATA_FAILURE,
    CREATE_LEARNING_HUB_SUCCESS,
    CREATE_LEARNING_HUB_FAILURE,
  } from "../actions/learninghubAction";
  
  const initialState = {
    selectedJobReferral: null,
    resumeReview: [],
    selectedResumeReview: null,
    dashboard: null,
    isAuthenticated: false,
    error: null,
    loading: false,
    learningHubData: [],
    learningData: [],
    createHubData: null,
    createHubError: null,
    learningUpdateData: [],
  };
  
  // Authentication Reducer
  const learninghubReducer = (state = initialState, action) => {
    switch (action.type) {


      case UPDATE_LEARNING_HUB_SUCCESS:
      return {
        ...state,
        learningUpdateData: state.learningUpdateData.map((item) =>
          item._id === action.payload._id ? action.payload : item
        ),
      };
      case UPDATE_LEARNING_HUB_FAILURE:
      return { ...state, error: action.payload };


      case CREATE_LEARNING_HUB_SUCCESS:
      return {
        ...state,
        loading: false,
        createHubData: action.payload, // Update the state with fetched data
        createHubError: null, // Clear any errors
      };

    case CREATE_LEARNING_HUB_FAILURE:
      return {
        ...state,
        loading: false,
        createHubData: null, // Reset the data on failure
        createHubError: action.payload.error, // Set the error message
      };

      case FETCH_LEARNING_HUB_SUCCESS:
        return {
          ...state,
          loading: false, // Turn off loading indicator
          learningData: action.payload, // Set the fetched data
          error: null, // Clear any existing errors
        };
  
      case FETCH_LEARNING_HUB_FAILURE:
        return {
          ...state,
          loading: false, // Turn off loading indicator
          learningData: [], // Clear the data in case of failure
          error: action.payload, // Set the error message
        };
  
      case FETCH_LEARNINGHUB_DATA_SUCCESS:
        return {
          ...state,
          loading: false, // Turn off loading indicator
          learningHubData: action.payload, // Set fetched learning hub data
          error: null, // Clear error on success
        };
  
      case FETCH_LEARNINGHUB_DATA_FAILURE:
        return {
          ...state,
          loading: false, // Turn off loading indicator
          learningHubData: [], // Reset data in case of failure
          error: action.payload, // Set error message
        };
  
      default:
        return state;
    }
  };
  
  export default learninghubReducer;
  