// Action Types
export const FETCH_DISCUSSIONS_SUCCESS = "FETCH_DISCUSSIONS_SUCCESS";
export const FETCH_DISCUSSIONS_FAILURE = "FETCH_DISCUSSIONS_FAILURE";
export const FETCH_TAGS_SUCCESS = "FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "FETCH_TAGS_FAILURE";
export const POST_DISCUSSION_SUCCESS = "POST_DISCUSSION_SUCCESS";
export const POST_DISCUSSION_FAILURE = "POST_DISCUSSION_FAILURE";
export const FETCH_DISCUSSION_By_Id_SUCCESS = "FETCH_DISCUSSION_By_Id_SUCCESS";
export const FETCH_DISCUSSION_By_Id_FAILURE = "FETCH_DISCUSSION_By_Id_FAILURE";


// Action Creators

// Success action
export const fetchDiscussionByIdSuccess = (data) => ({
    type: FETCH_DISCUSSION_By_Id_SUCCESS,
    payload: data,
});

// Failure action
export const fetchDiscussionByIdFailure = (error) => ({
    type: FETCH_DISCUSSION_By_Id_FAILURE,
    payload: error,
});


// Success action post
export const fetchDiscussionsSuccess = (data) => ({
    type: FETCH_DISCUSSIONS_SUCCESS,
    payload: data,
});

// Failure action post
export const fetchDiscussionsFailure = (error) => ({
    type: FETCH_DISCUSSIONS_FAILURE,
    payload: error,
});

// Success fetch all tag
export const fetchTagsSuccess = (data) => ({
    type: FETCH_TAGS_SUCCESS,
    payload: data,
});

// Failure fetch all tag
export const fetchTagsFailure = (error) => ({
    type: FETCH_TAGS_FAILURE,
    payload: error,
});

// Success create disscussion post
export const postDiscussionSuccess = (data) => ({
    type: POST_DISCUSSION_SUCCESS,
    payload: data,
});

// Failure create disscussion post
export const postDiscussionFailure = (error) => ({
    type: POST_DISCUSSION_FAILURE,
    payload: error,
});

