import React, { useState, useEffect } from "react"; 
import "./LearningHubFilter.css";
import searchIcon from "../../assets/images/searchIcon.svg";
import dropdownIcon from "../../assets/images/Learninghub-drop-down-arro.svg";
import { fetchLearningHubData } from "../../services/LearningHub/LearningHub";
import { useSelector, useDispatch } from "react-redux";

const LearningHubFilter = () => {
  const dispatch = useDispatch();
  // Redux state
  const { learningHubData, loading, error } = useSelector((state) => state.learninghub);
  const [searchQuery, setSearchQuery] = useState("");
  const [popularity, setPopularity] = useState("");

  useEffect(() => {
      dispatch(fetchLearningHubData({search: searchQuery, popularity}));
    }, [popularity,searchQuery,dispatch]);

  return (
    <div className="main-learninghub-filter">
      <h4 className="learninghub-filter-heading">Refine your search</h4>
      <p className="learninghub-filter-des">
        Find the resources you need by filtering through our webinars, articles,
        and quizzes. Select the category that match your interests and learning
        goals.
      </p>
      <div className="learninghub-filter-form-container">

        <div className="learninghub-filter-dropdown-container">

            <select
            className="learninghub-filter-form-input"
            value={popularity}
            onChange={(e) => setPopularity(e.target.value)}>
            <option value="" disabled>Popularity</option>
            <option value="latest">Latest</option>
            <option value="popular">Most Popular</option>
          </select>
          <img
            src={dropdownIcon}
            alt="Dropdown Arrow"
            className="learninghub-dropdown-icon"
          />
        </div>

       
        <div className="learninghub-search-container">
          <img
            className="learninghub-search-icon"
            src={searchIcon}
            alt="search icon"
          />
          <input
            className="learninghub-filter-form-input learninghub-search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default LearningHubFilter;
