import { showToastMessage } from "../../redux/actions/toastActions";
import { startLoading, stopLoading } from "../../redux/actions/spinnerActions";
import { apiRequest } from "../../utils/apiRequest";
import { 
  fetchDiscussionsSuccess, 
  fetchDiscussionsFailure,  
  fetchTagsSuccess, 
  fetchTagsFailure,
  postDiscussionSuccess, 
  postDiscussionFailure,
  fetchDiscussionByIdSuccess,
  fetchDiscussionByIdFailure
} from "../../redux/actions/discussionForumAction";


// Helper function to dispatch error messages
const handleApiError = (dispatch, error, errorMessage) => {
    console.error(errorMessage, error);
    dispatch(showToastMessage(errorMessage, "error"));
  };


 // API service for fetching ALL discussions
export const fetchDiscussions = (queryParams = {}) => {
  return async (dispatch) => {
      dispatch(startLoading());

      try {
          // Construct query string from queryParams
          const query = new URLSearchParams(queryParams).toString();
          const endpoint = `/api/discussions${query ? `?${query}` : ""}`;

          // Make the API request
          const response = await apiRequest(endpoint, {}, false, dispatch);

          // Handle successful response
          console.log("Fetched Discussions:", response.data);
          dispatch(fetchDiscussionsSuccess(response.data));
          dispatch(showToastMessage("Discussions fetched successfully!", "success"));
      } catch (error) {
          // Handle error response
          console.error("Failed to fetch discussions:", error);
          dispatch(fetchDiscussionsFailure(error));
          handleApiError(dispatch, error, "Failed to fetch discussions.");
      } finally {
          // Stop loading state
          dispatch(stopLoading());
      }
  };
};

// API service for fetching ALL tags
export const fetchTags = (queryParams = {}) => {
  return async (dispatch) => {
      dispatch(startLoading());

      try {
          // Construct query string from queryParams
          const query = new URLSearchParams(queryParams).toString();
          const endpoint = `/api/tags${query ? `?${query}` : ""}`;

          // Make the API request
          const response = await apiRequest(endpoint, {}, false, dispatch);

          // Handle successful response
          console.log("Fetched Tags:", response.data);
          dispatch(fetchTagsSuccess(response.data));
          dispatch(showToastMessage("Tags fetched successfully!", "success"));
      } catch (error) {
          // Handle error response
          console.error("Failed to fetch tags:", error);
          dispatch(fetchTagsFailure(error));
          handleApiError(dispatch, error, "Failed to fetch tags.");
      } finally {
          // Stop loading state
          dispatch(stopLoading());
      }
  };
};


// API service for posting a new discussion
// export const postDiscussion = (formData) => {
//   return async (dispatch) => {
//       dispatch(startLoading());

//       try {
//           const endpoint = "/api/discussions";
          
//           // Make the API request with formData
//           const response = await apiRequest(endpoint, {
//               method: "POST",
//               body: formData,
//               isFormData: true,
//           }, false, dispatch);
          
//           // Handle successful response
//           console.log("Discussion Posted:", response.data);
//           dispatch(postDiscussionSuccess(response.data));
//           dispatch(showToastMessage("Discussion posted successfully!", "success"));
//       } catch (error) {
//           // Handle error response
//           console.error("Failed to post discussion:", error);
//           dispatch(postDiscussionFailure(error));
//           handleApiError(dispatch, error, "Failed to post discussion.");
//       } finally {
//           dispatch(stopLoading());
//       }
//   };
// };
export const postDiscussion = (formData) => {
    // console.log("hi");
    
    return async (dispatch) => {
      dispatch(startLoading());
  
      try {
  
        const response = await apiRequest(
          "/api/discussions",  // Update to your actual API path
          {
            method: "POST",
            body: formData,
          },
          false,
          dispatch
        );
  
        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(
            `Error: ${response.statusText}. Server Response: ${JSON.stringify(errorResponse)}`
          );
        }
  
        const data = await response.json();
        console.log(data);
  
        dispatch(postDiscussionSuccess(data)); // Update the action name as necessary
        dispatch(stopLoading());
        return { status: "success", data };
      } catch (error) {
        console.error("API Error:", error);
        dispatch(postDiscussionFailure(error.message)); // Update failure action as necessary
        return { status: "error", error: error.message };
      }
    };
  };

//fetch discussion by Id 
  export const fetchDiscussionById = (id) => {
    return async (dispatch) => {
      dispatch(startLoading());
      console.log(id);
      
      try {
        const response = await apiRequest(
          `/api/discussions/${id}`,
          {},
          false,
          dispatch
        );
        console.log("Discussion Data:", response.data);
        dispatch(fetchDiscussionByIdSuccess(response.data));
        dispatch(showToastMessage("Discussion fetched successfully!", "success"));
      } catch (error) {
        console.error("Failed to fetch discussion:", error);
        dispatch(fetchDiscussionByIdFailure(error));
        handleApiError(dispatch, error, "Failed to fetch discussion.");
      } finally {
        dispatch(stopLoading());
      }
    };
  };
  