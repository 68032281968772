import React, { useEffect, useState } from "react";
import "./JobReferrals.css";
import TitlePath from "../../../../../components/TitlePath/TitlePath";
import { useNavigate, useParams } from "react-router-dom";
import chatIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/chatIcon.svg";
import resumeIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/resumeIcon.svg";
import coverLetterIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/coverLetterIcon.svg";
import createIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/createIcon.svg";
import alarmIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/alarmIcon.svg";
import checkIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/checkIcon.svg";
import hourglassIcon from "../../../../../assets/images/adminLayout/Dashboard/Icons/hourglassIcon.svg";
import arrowBack from "../../../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import outbox from "../../../../../assets/images/adminLayout/Dashboard/Icons/outbox.svg";
import ChatModal from "../../../ChatModal/ChatModal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  adminUpdateJobReferralStatusAyns,
  getAdminJobReferral,
} from "../../../../../services/jobSeeker/jobSeekerService";

const JobReferrals = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const jobReferralDetail = useSelector(
    (state) => state.jobSeeker.selectedAdminJobReferral || null
  );

  useEffect(() => {
    if (id && selectedStatus) {
      dispatch(
        adminUpdateJobReferralStatusAyns(id, { status: selectedStatus })
      );
    }
  }, [id, selectedStatus, dispatch]);

  useEffect(() => {
    if (id) {
      console.log("Dispatching getJobReferral with ID:", id);
      dispatch(getAdminJobReferral(id));
    }
  }, [dispatch, id]);

  console.log("jobReferralDetail", jobReferralDetail);

  const handleBack = () => {
    navigate(`/admin_dashboard/`);
  };

  const handleChatBack = () => {
    navigate(`/admin_dashboard/service_management`);
  };

  const handleChatBtn = () => {
    setIsModalOpen(true);
  };

  const closeChat = () => {
    setIsModalOpen(false);
  };

  const handleBackTable = () => {
    navigate(`/admin_dashboard/service_management`);
  };

  const titlePath = {
    exactPath: (
      <span style={{ cursor: "pointer" }} onClick={handleBackTable}>
        service_management
      </span>
    ),
    paths: [{ label: "Dashboard >", onClick: handleBack }],
  };
  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="jra-container">
        {/* Header */}
        <div className="jra-header">
          <div className="jra-header-title">
            {/* <span>←</span> */}
            <img
              src={arrowBack}
              className="jra-header-title-icon"
              onClick={handleChatBack}
            />
            <h4>
              Job Referrals -{" "}
              <span className="green-heading">
                {jobReferralDetail?.jobSeekerDetails?.name} -{" "}
                {jobReferralDetail?.index}
              </span>
            </h4>
          </div>
          <div className="jra-header-actions">
            <select
              value={selectedStatus}
              onChange={handleStatusChange}
              className="status-select"
            >
              <option value="" disabled className="status-option">
                Mark Status
              </option>
              <option value="completed" className="status-option completed">
                Mark it as Completed
              </option>
              <option value="rejected" className="status-option rejected">
                Mark it as Rejected
              </option>
              <option value="pending" className="status-option pending">
                Mark it as Pending
              </option>
              <option value="inProgress" className="status-option pending">
                Mark it as In-Progress
              </option>
            </select>

            <button className="jra-chat-button" onClick={handleChatBtn}>
              <img src={chatIcon} className="chatIcon" />
              Chat
              <span className="jra-notification-badge">15</span>
            </button>
          </div>
        </div>

        {/* Application Status */}
        <h5 className="jra-heading">Application Status</h5>
        <div className="jra-card">
          <div className="jra-status-row">
            <div>
              <p className="jra-status-label">Name</p>
              <p className="jra-status-value">
                {jobReferralDetail?.jobSeekerDetails?.name}
              </p>
            </div>
            <div>
              <div>
                <p className="jra-status-label">Submission Date</p>
                <p className="jra-status-value">
                  {" "}
                  {moment(jobReferralDetail?.submissionDate).format(
                    "YYYY-MM-DD"
                  )}
                </p>
              </div>
              <div>
                <p className="jra-status-label">Status</p>
                <span className="jra-status-badge">
                  {" "}
                  {jobReferralDetail?.currentStatus
                    ? jobReferralDetail.currentStatus.charAt(0).toUpperCase() +
                      jobReferralDetail.currentStatus.slice(1).toLowerCase()
                    : ""}
                </span>
              </div>
            </div>
          </div>

          <div>
            <p className="jra-status-label">Reject Reason</p>
            <p className="jra-status-value">
              {jobReferralDetail?.submittedProofs}
              {/* Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut.
              Lorem ipsum dolor sit amet, consectetur adipis cing elit, sed do
              eiusmod tempor incididunt ut Lorem ipsum dolor sit amet,
              consectetur adipis cing elit, sed do eiusmod tempor incididunt ut. */}
            </p>
          </div>
        </div>

        {/* User Information */}
        <h5 className="jra-heading">User Information</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">E-Mail Address</p>
              <p className="jra-status-value">
                {jobReferralDetail?.jobSeekerDetails?.email}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Phone Number</p>
              <p className="jra-status-value">
                {jobReferralDetail?.jobSeekerDetails?.mobile}
              </p>
            </div>
          </div>
        </div>

        {/* Industry Selection */}
        <h5 className="jra-heading">Industry Selection</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Industry</p>
              <p className="jra-status-value">
                {" "}
                {jobReferralDetail?.industryDetails?.industry}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Desired Position</p>
              <p className="jra-status-value">
                {" "}
                {jobReferralDetail?.industryDetails?.desiredPosition}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Desired Company</p>
              <p className="jra-status-value">
                {" "}
                {jobReferralDetail?.industryDetails?.desiredCompany}
              </p>
            </div>
            <div>
              <p className="jra-status-label">Current Job</p>
              <p className="jra-status-value">
                {jobReferralDetail?.industryDetails?.currentJob}
              </p>
            </div>
          </div>
        </div>

        {/* Application Details */}
        <h5 className="jra-heading">Application Details</h5>
        <div className="jra-card">
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Resume</p>
              <a
                href={`https://konect.sktgold.com/${jobReferralDetail?.resume}`}
                target="_blank"
                className="jra-file-link "
              >
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={resumeIcon} />
                {`https://konect.sktgold.com/${jobReferralDetail?.resume}`}
              </a>
            </div>
            <div>
              <p className="jra-status-label">Cover Letter</p>
              <a
                href={`https://konect.sktgold.com/${jobReferralDetail?.coverLetter}`}
                target="_blank"
                className="jra-file-link "
              >
                {/* <span className="jra-file-icon">📄</span> */}
                <img src={coverLetterIcon} />
                {`https://konect.sktgold.com/${jobReferralDetail?.coverLetter}`}{" "}
              </a>
            </div>
          </div>
        </div>

        {/* Payment Details */}
        <h5 className="jra-heading">Payment Details</h5>
        <div className="jra-card">
          <div className="payment-received-details">
            <h3 className="payment-subtitle">Payment Received Details</h3>
            <div className="payment-row">
              <span className="payment-label">Reference</span>
              <span className="payment-value">
                {" "}
                {jobReferralDetail?.payment?._id}
              </span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Date</span>
              <span className="payment-value">
                {" "}
                {moment(jobReferralDetail?.payment?.date).format("YYYY-MM-DD")}
              </span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Method</span>
              <span className="payment-value">
                {jobReferralDetail?.payment?.paymentMethod}
              </span>
            </div>
            <div className="payment-row">
              <span className="payment-label">Amount</span>
              <span className="payment-value">
                {jobReferralDetail?.payment?.amount}
              </span>
            </div>
          </div>
        </div>

        {/* Status Timeline */}
        <h5 className="jra-heading">Status</h5>
        <div className="jra-card">
          <div className="jra-timeline">
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={createIcon} />
              <div className="jra-timeline-label">Created</div>
              <div className="jra-timeline-date">
                {moment(jobReferralDetail?.payment?.createdAt).format(
                  "YYYY-MM-DD"
                )}
              </div>
            </div>
            <hr className="jra-timeline-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={alarmIcon} />
              <div className="jra-timeline-label">Progress Started</div>
              <div className="jra-timeline-date">
                {moment(jobReferralDetail?.payment?.updatedAt).format(
                  "YYYY-MM-DD"
                )}
              </div>
            </div>
            <hr className="jra-timeline-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={hourglassIcon} />
              <div className="jra-timeline-label">Waiting for Approval</div>
              <div className="jra-timeline-date">
                {moment(jobReferralDetail?.payment?.createdAt).format(
                  "YYYY-MM-DD"
                )}
              </div>
            </div>
            <hr className="jra-timeline-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={outbox} />
              <div className="jra-timeline-label">Submit Proof</div>
              <div className="jra-timeline-date">
                {moment(jobReferralDetail?.payment?.createdAt).format(
                  "YYYY-MM-DD"
                )}
              </div>
            </div>
            <hr className="jra-timeline-hr" />
            <div className="jra-timeline-item">
              <img className="jra-timeline-icon" src={checkIcon} />
              <div className="jra-timeline-label">Approved</div>
              <div className="jra-timeline-date">
                {moment(jobReferralDetail?.payment?.createdAt).format(
                  "YYYY-MM-DD"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ChatModal
        // job={job}
        isOpen={isModalOpen}
        onClose={closeChat}
        requestId={id}
        jobReferralDetail={jobReferralDetail}
      />
    </div>
  );
};
export default JobReferrals;
