import React from "react";
import "./TitlePath.css";
import NotificationIcon from "../../assets/images/Bell-icon.svg";

const TitlePath = ({
  exactPath,
  paths, // array of objects containing path and onClick function
  showNotification, // Boolean to decide whether to show the notification icon
}) => {
  return (
    <div className="path-container">
      <div className="file-path-place">
        {paths.map((path, index) => (
          <div
            key={index}
            className="path user_dashboard_path"
            onClick={path.onClick}
          >
            {/* {path.label} */}
          </div>
        ))}
        {/* <div className="exact-path user_dashboard_exact_path ">{exactPath}</div> */}
      </div>
      {showNotification && (
        <img className="Notification-icon" src={NotificationIcon}></img>
      )}
    </div>
  );
};

export default TitlePath;
