import React, { useState,useEffect } from "react";
import FileUpload from "../../../../components/FileUpload/FileUpload";
// import "..//JobReferralsNewRequest.css";
import "../../JobReferrals/JobReferralsNewRequest/JobReferralsNewRequest";
import questionCircle from "../../../../assets/images/UserLayout/Icons/question-circle.svg";
import creditCard from "../../../../assets/images/UserLayout/Icons/credit_card.svg";
import closeIcon from "../../../../assets/images/UserLayout/Icons/close.svg";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addResumeReviewRequest,fetchIndustries,
  fetchCompanies } from "../../../../services/jobSeeker/jobSeekerService";
import ReviewLoader from "../../../../components/Loader/ReviewLoader/ReviewLoader";
import FormLoader from "../../../../components/Loader/FormLoader/FormLoader";
  

const ResumeReviewNewRequest = () => {
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const userData = JSON.parse(localStorage.getItem("user"));


  const industries = useSelector((state) => state.jobSeeker.industries || []);
  const companies = useSelector((state) => state.jobSeeker.companies || []);
  const [industry,setIndustry] =useState([]);
  const [company,setCompany] = useState([]);
  const [submit,setSubmit] = useState(false);
  const [errors, setErrors] = useState({}); // state to hold validation errors


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name:
      userData.user.personalInfo.firstName +
      "\u00A0" +
      userData.user.personalInfo.lastName,
    email: userData.user.email,
    mobile: userData.user.mobile,
    industry: "",
    desiredPosition: "",
    desiredCompany: "",
    currentJob: "",
    paymentMethod: "CreditCard",
    paidDate: new Date().toISOString(),
    paidAmount: "500",
  });


  useEffect(() => {
      const fetchData = async () => {
        try {
          await dispatch(fetchIndustries());
          await dispatch(fetchCompanies());
        } catch (error) {
          console.error("Error fetching industries or companies:", error);
        }
      };
      fetchData();
    }, [dispatch]);
  
    useEffect(() => {
      if (industries && industries.length>0){
        setIndustry(industries)
      }
    }, [industries]);
  
  
    useEffect(() => {
      if (companies && companies.length>0){
        setCompany(companies)
      }
    }, [companies]);

  const handleResumeUpload = (file) => {
    console.log("Resume Uploaded:", file);
    setResume(file);
  };

  const handleCoverLetterUpload = (file) => {
    console.log("Cover Letter Uploaded:", file);
    setCoverLetter(file);
  };



  const handleInputChange = (e, fieldName = null) => {
    const { name, value } = e.target;

    // Clear the error message for the field being edited
  setErrors((prevErrors) => ({
    ...prevErrors,
    [fieldName || name]: "",
  }));
  
    setFormData((prevData) => ({
      ...prevData,
      [fieldName || name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.mobile) newErrors.mobile = "Phone number is required.";
    if (!formData.industry) newErrors.industry = "Industry selection is required.";
    if (!formData.desiredPosition) newErrors.desiredPosition = "Desired position is required.";
    if (!formData.desiredCompany) newErrors.desiredCompany = "Desired company is required.";
    if (!formData.currentJob) newErrors.currentJob = "Current job is required.";
    if (!resume) newErrors.resume = "Resume is required.";
    if (!coverLetter) newErrors.coverLetter = "Cover letter is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // returns true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();
    if (!isValid) return; // stop submission if form is invalid

    const submissionData = {
      ...formData,
      resume: resume || null,
      coverLetter: coverLetter || null,
    };
    console.log(submissionData);
setSubmit(resume&&coverLetter)
    const response = await dispatch(addResumeReviewRequest(submissionData));
    console.log(response?.data?.statusCode);

    if (response?.data?.statusCode === 200 && response?.data?.data?._id) {
      navigate(`/user_dashboard/resume_review/${response?.data?.data?._id}`);
    }
  };

  const handleCancel = () => {
    navigate("/user_dashboard/resume_review");
  };

  return (
    <div className="user-job-referral-form-request-container">
     {submit? <ReviewLoader/>:
      <FormLoader/>}

      <h4>New Resume Review</h4>
      <p>
        Please fill in the information below to start the resume review process.
      </p>

      <form className="user-job-referral-form-request" onSubmit={handleSubmit}>
        <div>
          <h5>User Information Form</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-input"
                  placeholder="Enter your full name"
                  // required
                  value={formData.name}
                  onChange={handleInputChange}
                />
                 {errors.name && <p className="validation-error">{errors.name}</p>}
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  E-Mail Address<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  className="form-input"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleInputChange}
                  // required
                />
                 {errors.email && <p className="validation-error">{errors.email}</p>}
              </div>
            </div>
            <div className="user-job-referral-form-input-container">
              <label className="form-label">
                Your Phone Number<span className="required">*</span>
              </label>
              <input
                type="text"
                name="mobile"
                className="form-input"
                placeholder="Enter your phone number"
                value={formData.mobile}
                onChange={handleInputChange}
                // required
              />
              {errors.mobile && <p className="validation-error">{errors.mobile}</p>}
            </div>
          </div>
        </div>

        <div>
          <h5>Industry Selection</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Select Your Industry<span className="required">*</span>
                </label>
                <select
                  name="industry"
                  class="form-input"
                  value={formData.industry}
                  onChange={handleInputChange}
                  // required
                >
                  <option value="" disabled selected>
                    Choose the industry that matches your desired position
                  </option>
                  {industry.length > 0 ? (
                    industry.map((industry, index) => (
                      <option key={index} value={industry}>
                        {industry}
                      </option>
                    ))
                  ) : (
                    <option>Loading industries...</option>
                  )}
                </select>
                {errors.industry && <p className="validation-error">{errors.industry}</p>}
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Desired Position<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="desiredPosition"
                  className="form-input"
                  value={formData.desiredPosition}
                  onChange={handleInputChange}
                  placeholder="Enter the URL of your desired position"
                  // required
                />
                 {errors.desiredPosition && <p className="validation-error">{errors.desiredPosition}</p>}
              </div>
            </div>

            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Select Desired Company<span className="required">*</span>
                </label>
                <select
                  name="desiredCompany"
                  class="form-input"
                  value={formData.desiredCompany}
                  onChange={handleInputChange}
                  // required
                >
                  <option value="" disabled selected>
                    Choose a desired company
                  </option>
                  {company.length > 0 ? (
                    company.map((companyItem) => (
                      <option key={companyItem._id} value={companyItem._id}>
                        {companyItem.name}
                      </option>
                    ))
                  ) : (
                    <option>Loading companies...</option>
                  )}
                </select>
                {errors.desiredCompany && <p className="validation-error">{errors.desiredCompany}</p>}
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Current Job<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="currentJob"
                  className="form-input"
                  value={formData.currentJob}
                  onChange={handleInputChange}
                  placeholder="Enter the URL of your current job"
                  // required
                />
                 {errors.currentJob && <p className="validation-error">{errors.currentJob}</p>}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="upload-section">
            <h5>Upload Section</h5>
            <FileUpload
              fileHeading="Upload Your Resume"
              uploadDes="Drag and Drop or Choose your resume to upload"
              onFileSelect={handleResumeUpload}
              isRequired={true}
            />
            {errors.resume && <p className="validation-error">{errors.resume}</p>}
          </div>

          <div className="upload-section">
            <FileUpload
              fileHeading="Upload Your Cover Letter (Optional)"
              uploadDes="Drag and Drop or Choose your cover letter to upload"
              onFileSelect={handleCoverLetterUpload}
              isRequired={false}
            />
             {errors.coverLetter && <p className="validation-error">{errors.coverLetter}</p>}
            <p className="upload-des">
              <img src={questionCircle} className="question-circle-icon" />
              Don’t have a Resume or Cover Letter? Click Here
            </p>
          </div>

          
        </div>
        <div className="user-request-form-actions">
          <button
            className="button-common user-dashboard-table-button pay-cancel-button"
            onClick={handleCancel}
          >
            <img src={closeIcon} class="user-dashboard-table-button-icon" />
            Cancel
          </button>
          <button className="button-common user-dashboard-table-button">
            <img src={creditCard} class="user-dashboard-table-button-icon" />
            Finalize
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResumeReviewNewRequest;
