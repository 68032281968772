import React, { useState, useEffect } from "react";
import FileUpload from "../../../components/FileUpload/FileUpload";
import "./AccVerification.css";
import questionCircle from "../../../assets/images/UserLayout/Icons/question-circle.svg";
import creditCard from "../../../assets/images/UserLayout/Icons/credit_card.svg";
import closeIcon from "../../../assets/images/UserLayout/Icons/close.svg";
import { useNavigate } from "react-router-dom";
import { createAccountVerification } from "../../../services/referrer/referrerService";
import { useDispatch, useSelector } from "react-redux";

const AccVerification = () => {
  const [resume, setResume] = useState(null);
  const [coverLetter, setCoverLetter] = useState(null);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("user")) || {};

  const [formData, setFormData] = useState({
    firstName: `${userData?.user?.personalInfo?.firstName || ""}`,
    lastName: ` ${userData?.user?.personalInfo?.lastName || ""}`,
    email: userData?.user?.email || "",
    mobile: userData?.user?.mobile || "",
    mobileOffice:"",
    companyName:"",
    companyAddress:"",
    companyWebsite: "",
    companyContactNumber: "",
    positionAtCompany: userData?.user?.careerInfo?.positionAtCompany || "",
  });

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "name") {
      const [firstName, ...lastNameArr] = value.split(" ");
      setFormData({
        ...formData,
        name: value,
        firstName: firstName || "", // First name is the first part
        lastName: lastNameArr.join(" ") || "", // Join the remaining parts as the last name
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    // Dynamically restrict input to digits and maximum length of 15 for phone numbers
    if (
      ["mobile", "mobileOffice", "companyContactNumber"].includes(name) &&
      (isNaN(value) || value.length > 15)
    ) {
      setErrors({
        ...errors,
        [name]: "Only numeric values up to 15 digits are allowed.",
      });
      return;
    }

    // Dynamically restrict input for fields that allow only letters and spaces
    if (
      ["name", "companyName", "positionAtCompany"].includes(name) &&
      (!/^[A-Za-z\s]*$/.test(value) || value.length > 200)
    ) {
      setErrors({
        ...errors,
        [name]: "Only letters and spaces (up to 200 characters) are allowed.",
      });
      return;
    }

    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear error when input is valid
  };

  // const handleFileUpload = (file, field) => {
  //   if (field === "resume") {
  //     setResume(file);
  //     setErrors({ ...errors, resume: "" });
  //   } else if (field === "coverLetter") {
  //     setCoverLetter(file);
  //     setErrors({ ...errors, coverLetter: "" });
  //   }
  // };

  const handleFileUpload = (file, field) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
  
    if (!allowedTypes.includes(file.type)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: "Only jpeg, jpg, png & pdf files are supported!",
      }));
      return;
    }
  
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
  
    if (field === "resume") {
      setResume(file);
    } else if (field === "coverLetter") {
      setCoverLetter(file);
    }
  };
  

  const validateForm = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{1,15}$/; // Allows only digits with a maximum length of 15
    const lettersOnlyRegex = /^[A-Za-z\s]{1,200}$/;

    // Field validations
    if (!formData.name) newErrors.name = "Name is required.";
    else if (!lettersOnlyRegex.test(formData.name))
      newErrors.name =
        "Name should only contain letters and spaces (up to 200 characters).";

    if (!formData.email) newErrors.email = "Email is required.";
    else if (!emailRegex.test(formData.email))
      newErrors.email = "Invalid email address.";

    if (!formData.mobile) newErrors.mobile = "Phone number is required.";
    else if (!phoneRegex.test(formData.mobile))
      newErrors.mobile =
        "Phone number must be numeric and up to 15 digits only.";

    if (!formData.mobileOffice)
      newErrors.mobileOffice = "Office phone number is required.";
    else if (!phoneRegex.test(formData.mobileOffice))
      newErrors.mobileOffice =
        "Office phone number must be numeric and up to 15 digits only.";

    if (!formData.companyName)
      newErrors.companyName = "Company name is required.";
    else if (!lettersOnlyRegex.test(formData.companyName))
      newErrors.companyName =
        "Company name should only contain letters and spaces (up to 200 characters).";

    if (!formData.companyAddress)
      newErrors.companyAddress = "Company address is required.";
    if (!formData.companyWebsite) newErrors.companyWebsite = "Website is required.";

    if (!formData.companyContactNumber)
      newErrors.companyContactNumber = "Company contact number is required.";
    else if (!phoneRegex.test(formData.companyContactNumber))
      newErrors.companyContactNumber =
        "Company contact number must be numeric and up to 15 digits only.";

    if (!formData.positionAtCompany)
      newErrors.positionAtCompany = "Referrer's Current Position is required.";
    else if (!lettersOnlyRegex.test(formData.positionAtCompany))
      newErrors.positionAtCompany =
        "Referrer's Current Position should only contain letters and spaces (up to 200 characters).";

    if (!resume) newErrors.resume = "Passport upload is required.";
    if (!coverLetter) newErrors.coverLetter = "User image upload is required.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleResumeUpload = (file) => {
    setResume(file);
  };

  const handleCoverLetterUpload = (file) => {
    setCoverLetter(file);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (validateForm()) {

  //     localStorage.setItem("isSubmitted", "true");

  //     navigate("/referrer_varification/referrer_info");
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const formDataObj = new FormData();
    Object.entries(formData).forEach(([key, value]) => formDataObj.append(key, value));
    formDataObj.append("passport", resume);
    formDataObj.append("picture", coverLetter);

    try {
      await dispatch(createAccountVerification(formDataObj));

      localStorage.setItem(`isSubmitted_${userData?.user?.id}`, "true");


      // Ensure successful submission before navigating
      navigate("/referrer_varification/referee_verification");
    } catch (error) {
      console.error("Submission error:", error);
    }
  };

  // useEffect(() => {
  //   // Check if the form is already submitted
  //   if (localStorage.getItem("isSubmitted") === "true") {
  //     navigate("/referrer_varification/referrer_info");

  //     // localStorage.clear();
  //   }
  // }, [navigate]);
  
  useEffect(() => {
    if (localStorage.getItem(`isSubmitted_${userData?.user?.id}`) === "true") {
      navigate("/referrer_varification/referee_verification");
    }
  }, [navigate, userData?.user?.id]);
  

  return (
    <div className="user-job-referral-form-request-container">
      <h4>Account Verification</h4>
      <h5 className="referral-form-sub-heading">Provide Your Details</h5>
      <p>
        Please fill in the information below to start the referral process.{" "}
      </p>

      <form className="user-job-referral-form-request">
        <div>
          <h5>User Information Form</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-input"
                  placeholder="Enter your full name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                {errors.name && (
                  <span className="error-message">{errors.name}</span>
                )}
              </div>

              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  E-Mail Address<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="email"
                  className="form-input"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleInputChange}
                />

                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>
            </div>

            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Phone Number<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="mobile"
                  className="form-input"
                  placeholder="Enter your phone number"
                  value={formData.mobile}
                  onChange={handleInputChange}
                />

                {errors.mobile && (
                  <span className="error-message">{errors.mobile}</span>
                )}
              </div>

              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Phone Number (Office)<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="mobileOffice"
                  className="form-input"
                  placeholder="Enter your phone number"
                  value={formData.mobileOffice}
                  onChange={handleInputChange}
                />
                {errors.mobileOffice && (
                  <span className="error-message">{errors.mobileOffice}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <h5>Company Details</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Name<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="companyName"
                  className="form-input"
                  placeholder="Enter your company name"
                  value={formData.companyName}
                  onChange={handleInputChange}
                />
                {errors.companyName && (
                  <span className="error-message">{errors.companyName}</span>
                )}
              </div>

              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Address<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="companyAddress"
                  className="form-input"
                  placeholder="Enter your company address"
                  value={formData.companyAddress}
                  onChange={handleInputChange}
                />
                {errors.companyAddress && (
                  <span className="error-message">{errors.companyAddress}</span>
                )}
              </div>
            </div>
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Website<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="companyWebsite"
                  className="form-input"
                  placeholder="Enter your company companyWebsite"
                  value={formData.companyWebsite}
                  onChange={handleInputChange}
                />

                {errors.companyWebsite && (
                  <span className="error-message">{errors.companyWebsite}</span>
                )}
              </div>
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Contact Number<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="companyContactNumber"
                  className="form-input"
                  placeholder="Enter your company contact number"
                  value={formData.companyContactNumber}
                  onChange={handleInputChange}
                />

                {errors.companyContactNumber && (
                  <span className="error-message">
                    {errors.companyContactNumber}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <h5>Current Position</h5>
          <div className="user-job-referral-form-group">
            <div className="sub-user-job-referral-form-group">
              <div className="user-job-referral-form-input-container">
                <label className="form-label">
                  Referrer's Current Position<span className="required">*</span>
                </label>
                <input
                  type="text"
                  name="positionAtCompany"
                  className="form-input"
                  placeholder="Enter your current positionAtCompany"
                  value={formData.positionAtCompany}
                  onChange={handleInputChange}
                />

                {errors.positionAtCompany && (
                  <span className="error-message">{errors.positionAtCompany}</span>
                )}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="upload-section">
            <h5>Identity Verification</h5>
            <FileUpload
              fileHeading="Passport"
              uploadDes="Drag and Drop or Choose your Passport to upload"
              onFileSelect={(file) => handleFileUpload(file, "resume")}
              isRequired={true}
            />
            {errors.resume && (
              <span className="error-message">{errors.resume}</span>
            )}
          </div>

          <div className="upload-section">
            <FileUpload
              fileHeading="User Image Upload"
              uploadDes="Drag and Drop or Choose your User Image to upload or Take Photo"
              onFileSelect={(file) => handleFileUpload(file, "coverLetter")}
              isRequired={true}
            />
            {errors.coverLetter && (
              <span className="error-message">{errors.coverLetter}</span>
            )}
          </div>
        </div>

        <div className="user-request-form-actions">
          <button className="button-common user-dashboard-table-button pay-cancel-button">
            Cancel
          </button>
          <button
            className="button-common user-dashboard-table-button"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AccVerification;
