import React, { useState } from "react";
import "./DashboardTable.css";
import FillerByIcon from "../../assets/images/adminLayout/Dashboard/Icons/FillerByIcon.svg";
import SortByIcon from "../../assets/images/adminLayout/Dashboard/Icons/SortByIcon.svg";
import doubleArrowRight from "../../assets/images/doubleArrowRight.svg";
import singleArrowRight from "../../assets/images/singleArrowRight.svg";
import Pagination from "../../components/Pagination/Pagination";
import moment from "moment";

const DashboardTable = ({
  tableData,
  tabs,
  activeTab,
  setActiveTab,
  tableHeading,
  handleViewClick,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div>
      <div className="tabs">
        {tabs.map((tab) => (
          <button
            key={tab.name}
            className={`tab ${activeTab === tab.name ? "active" : ""}`}
            onClick={() => setActiveTab(tab.name)}
          >
            {tab.name} <span className="tab-count">{tab.count}</span>
          </button>
        ))}
      </div>

      <div className="search-filter-container">
        <div>
          <i class="fa-solid fa-magnifying-glass"></i>
          <input className="table-search-input" placeholder="Search post" />
        </div>
        <div>
          {/* <button className="filter-sort-button">
            <img src={FillerByIcon} className="filter-sort-button-icon" />
            Filter by
          </button>
          <button className="filter-sort-button">
            {" "}
            <img src={SortByIcon} className="filter-sort-button-icon" />
            Sort by
          </button> */}
        </div>
      </div>
      <div className="blog-post-table">
        <table>
          <thead>
            <tr>
              <td>
                <input type="checkbox" />
              </td>
              {tableHeading.map((data) => (
                <th key={data.key}>{data.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((row, rowIndex) => (
              <tr key={rowIndex} onClick={() => handleViewClick(row.id)}>
                <td>
                  <input type="checkbox" />
                </td>
                {tableHeading.map((column) => (
                  <td key={column.key}>
                    {column.key === "action" ? (
                      <button className="action-btn edit">
                        <i className="fa-regular fa-pen-to-square"></i>
                      </button>
                    ) : column.key === "status" ||
                      column.key === "currentStatus" ? (
                      <span
                        className={`status ${row[column.key].toLowerCase()}`}
                      >
                        {row[column.key].charAt(0).toUpperCase() +
                          row[column.key].slice(1).toLowerCase()}
                      </span>
                    ) : column.key === "submissionDate" ? (
                      moment(row[column.key]).format("DD MMM YYYY")
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Component */}
      <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            paginate={paginate}
            singleArrowRight={singleArrowRight}
            doubleArrowRight={doubleArrowRight}
          />
    </div>
  );
};

export default DashboardTable;
