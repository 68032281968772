import React, {useEffect} from "react";
import "./RecentlyVisited.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import recentlyViewedImg from "../../assets/images/recentlyVieweddiscussion.jpg";
import recentlyDot from "../../assets/images/Recently-dot.svg";
import recentlyVistedArrow from "../../assets/images/recently-visited-arrow.svg";
import webinarHost1 from "../../assets/images/webinarHost1.jpg";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDiscussions, fetchDiscussionById  } from "../../services/DiscussionForum/DiscussionForum";
import { useSelector, useDispatch } from "react-redux";

const RecentlyVisited = () => {
   const dispatch = useDispatch();

   const { id } = useParams();

     console.log(id);
     
  
  // Redux state
  const {  discussionById, discussions, loading, error } = useSelector((state) => state.discussionForum);

    useEffect(() => {
      dispatch(
        fetchDiscussions({
          page: 1,
          limit: 10,
          search: "What are the best practices",
          isDashboard: false,
          status: "published",
          tag: "API",
          type: "recent",
          isRecentlyVisited: true,
        })
      );
    
      if (id) {
        dispatch(fetchDiscussionById(id));
      }
    }, [dispatch, id]); 
    

  const navigate = useNavigate();

  const handleCreatePost = () => {
    navigate("/createDiscussion");
  };

  return (
    <div className="recently-visit-container">
      <div>
        <CustomButton
          label="+  Create Post"
          onClick={handleCreatePost}
          type="submit create-post-button"
        />
      </div>
      <div className="host-container">
        <div className="host-img-container">
          {/* <img className="webinar-host-img" src={discussionById?.[0]?.user?.picture} alt="host" /> */}
          <img className="webinar-host-img" src={discussionById?.user?.lastName || 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'} 
          onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
          alt="host"
           />
        </div>
        <div className="host-details">
          {/* <div>{discussions?.[0]?.user?.firstName} {discussions?.[0]?.user?.lastName}</div> */}
          <div>{discussionById?.user?.firstName}</div>
          <div>{discussionById?.user?.positionAtCompany}</div>
        </div>
      </div>
      <div>
        <div className="topic-heading">Related Topics</div>
        {discussionById?.relatedTopics?.map((data) => (
          <div className="related-topics-container">{data.title}</div>
        ))}
      </div>

      <div className="recently-visit-container-first-div">
        <div className="topic-heading">Recently Visited</div>

         {/* Handle Loading State */}
      {loading && <p>Loading discussions...</p>}

         {/* Handle Error State */}
         {error && <p className="error-text">Error fetching discussions: {error}</p>}
        
        <div className="recently-visited-img-container">
          <img className="recently-visited-img" src={discussions?.[0]?.image || 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}  
          onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'} />
        </div>
        <div className="topic-des-container">
          <div>
            {/* What Are the Best Strategies for Networking at Industry Events? */}
            {discussions?.[0]?.title}
          </div>
          <div>
            
            <img
              className="recently-arrow-props"
              src={recentlyVistedArrow}
            ></img>
          </div>
        </div>
        <div className="recently-visited-postedby">Posted by {discussions?.[0]?.user?.firstName} {discussions?.[0]?.user?.lastName}</div>
        <div className="upvote-comments-container">
          <CustomButton
            label={`${discussions?.[0]?.upVotes} Upvote`}
            onClick={() => console.log("Button clicked!")}
            type="backto-button upvote-comments-button"
          />{" "}
          <img className="recent-dot" src={recentlyDot}></img>
          <CustomButton
            label={`${discussions?.[0]?.commentsCount} comments Counts`}
            onClick={() => console.log("Button clicked!")}
            type="backto-button upvote-comments-button"
          />
        </div>
      </div>
      <div className="topic-heading">Burning Topics</div>
      <div>
        {!loading && !error && discussions.length > 0 ? (
        discussions.map((data) => (
          <div className="burning-topic-container">
            <div className="topic-des-container">
              <div>{data.title}</div>
              <div>
                
                <img
                  className="recently-arrow-props"
                  src={recentlyVistedArrow}
                ></img>
              </div>
            </div>
            <div className="recently-visited-postedby"> Posted by {data.user.firstName} {data.user.lastName}</div>
            <div className="upvote-comments-container">
              <CustomButton
                label={`${data.upVotes} Upvote`}
                onClick={() => console.log("Button clicked!")}
                type="backto-button upvote-comments-button"
              />{" "}
              <img className="recent-dot" src={recentlyDot}></img>
              <CustomButton
                label={`${data.commentsCount} Comments Counts`}
                onClick={() => console.log("Button clicked!")}
                type="backto-button upvote-comments-button"
              />
            </div>
          </div>
        ))) : (
        !loading && <p>No recently visited discussions available.</p>
      )}
      </div>
    </div>
  );
};

export default RecentlyVisited;