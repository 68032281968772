// import React, { useEffect, useState } from "react";
// import "./DiscussionPost.css";
// import webinarHost1 from "../../assets/images/webinarHost1.jpg";
// import profile2 from "../../assets/images/profile2.jpg";
// import upArrow from "../../assets/images/arrow-badge.svg";
// import downArrow from "../../assets/images/arrow-down-badge.svg";
// import CustomButton from "../../components/CustomButton/CustomButton";
// import { useParams } from "react-router-dom";
// import { fetchDiscussionById, postDiscussion } from "../../services/DiscussionForum/DiscussionForum";
// import { useSelector, useDispatch } from "react-redux";

// const DiscussionPost = () => {
//   const { id } = useParams();
  
  
//    const dispatch = useDispatch();
//    const [source, setSource] = useState(""); // Define state for comment input


//   // Redux state
//      const {  discussionById, loading, error } = useSelector((state) => state.discussionForum);
//    useEffect(() => {
//         dispatch(fetchDiscussionById(id));
//       }, [dispatch, id]);


//       console.log(id);



//       const handleCommentSubmit = async () => {
//         if (!source.trim()) {
//           alert("Comment cannot be empty.");
//           return;
//         }
      
//         try {
//           const response = await dispatch(postDiscussion({ id, content: source }));
      
//           if (postDiscussion.fulfilled.match(response)) {
//             alert("Comment posted successfully!");
//             setSource(""); // Clear input after submission
//             dispatch(fetchDiscussionById(id)); // Refresh discussion to show new comments
//           } else {
//             alert("Error posting comment");
//           }
//         } catch (error) {
//           console.error("Error submitting comment:", error);
//           alert("An error occurred. Please try again.");
//         }
//       };
      
      
      
//   const postAnswers = [
//     {
//       profileImg: profile2,
//       name: "Posted by Sarah_MarketingPro",
//       date: "27 April 2024",
//       ans: "Congratulations on attending your first industry conference! Here are some strategies that have worked for me:",
//     },
//     {
//       profileImg: profile2,
//       name: "Posted by John_ITSpecialist",
//       date: "27 April 2024",
//       ans: "Networking can be daunting, but it’s incredibly rewarding. Here are a few tips:",
//     },
//     {
//       profileImg: profile2,
//       name: "Posted by Robert_SalesGuru",
//       date: "27 April 2024",
//       ans: "Networking is key to building professional relationships. Here are some strategies:",
//     },
//   ];

//   return (
//     <div className="main-discussions-container">
//       <div className="main-discussion-post-container">
//         <div className="learninghub-name-date-container">
//           {/* <div>Posted by Alex Carl</div>
//           <div>27 April 2024</div> */}
//           <div>Posted by {discussionById?.user?.firstName || "Unknown"}</div>
//           <div>{new Date(discussionById?.publishedAt).toDateString()}</div>
//         </div>
//         <div className="discussion-post-container">
//           <div className="discussion-profile-container">
//             <div className="host-img-container">
//               <img className="webinar-host-img" src={webinarHost1} alt="host"                   
//               onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
//               />
//             </div>
//             <div className="discussions-number-container">
//               <img className="up-down-arrow" src={upArrow} />
//               <div>24</div>
//               <img className="up-down-arrow" src={downArrow} />
//             </div>
//           </div>

//           <div className="discussion-post-description-container">
//             <h5>
//               {/* What Are the Best Strategies for Networking at Industry Events? */}
//               {discussionById?.title || "Unknown title"}
//             </h5>
//             <p className="discussion-post-des">
//               {/* I'm attending my first major industry conference next month and I
//               want to make the most of the networking opportunities. What are
//               some effective strategies for connecting with professionals at
//               these events? How can I make a lasting impression and build
//               meaningful relationships? Any advice or personal experiences would
//               be greatly appreciated! */}
//               {discussionById?.content || "Unknown content"}
//             </p>
//             <div className="comment-share-button-container">
//               <CustomButton
//                 // label="24 Upvotes"
//                 label= {`${discussionById?.upVotes} Upvote`}
//                 onClick={() => console.log("Button clicked!")}
//                 type="comment-share-button"
//               />
//               <CustomButton
//                 // label="21 Comments"
//                 label= {`${discussionById?.commentsCount} Comments`}
//                 onClick={() => console.log("Button clicked!")}
//                 type="comment-share-button"
//               />
//               <CustomButton
//                 // label="8 Shares"
//                 label= {`${discussionById?.sharesCount} Shares`}
//                 onClick={() => console.log("Button clicked!")}
//                 type="comment-share-button"
//               />
//             </div>
//             <input
//               className="post-comment-input"
//               placeholder="Share your thoughts here..."
//               value={source}
//               onChange={(e) => setSource(e.target.value)}
//             />

//             <div className="contact-form-button-container">
//               <CustomButton
//                 label="Cancel"
//                 // onClick={() => console.log("Button clicked!")}
//                 onClick={() => setSource("")}
//                 type="contact-form-button discussion-comment-button"
//               />
//               <CustomButton
//                 label="Comment"
//                 // onClick={() => console.log("Button clicked!")}
//                 onClick={handleCommentSubmit} 
//                 type="contact-form-button-purple discussion-comment-button"
//               />
//             </div>
//           </div>
//         </div>

//         <h6>12 Answers</h6>
//         {discussionById?.commentPosts?.map((data) => (
//           <div className="answers-container">
//             <div className="learninghub-name-date-container">
//               <div>{data?.user?.firstName}</div>
//               <div>
//                 {/* {data?.publishedAt} */}
//                 {new Date(data?.publishedAt).toDateString()}

//               </div>
//             </div>

//             <div className="discussion-post-container">
//               <div className="discussion-profile-container">
//                 <div className="host-img-container">
//                   <img
//                     className="webinar-host-img"
//                     // src={data.profileImg}
//                     src={data?.user?.lastName}
//                     alt="host"
//                     onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
//                   />
//                 </div>
//                 <div className="discussions-number-container">
//                   <img className="up-down-arrow" src={upArrow} />
//                   <div>24</div>
//                   <img className="up-down-arrow" src={downArrow} />
//                 </div>
//               </div>

//               <div className="discussion-post-description-container">
//                 {/* <p className="discussion-ans">{data.ans}</p> */}
//                 <p className="discussion-ans">{data.content}</p>

//                 <div className="comment-share-button-container">
//                   <CustomButton
//                     // label="24 Upvotes"
//                     label= {`${data?.upVotes} Upvote`}
//                     onClick={() => console.log("Button clicked!")}
//                     type="comment-share-button"
//                   />
//                   <CustomButton
//                     // label="21 Comments"
//                     label= {`${data?.upVotes} Comments`}
//                     onClick={() => console.log("Button clicked!")}
//                     type="comment-share-button"
//                   />
//                   <CustomButton
//                     // label="8 Shares"
//                     label= {`${data?.sharesCount} Shares`}
//                     onClick={() => console.log("Button clicked!")}
//                     type="comment-share-button"
//                   />
//                   <CustomButton
//                     label="Comment"
//                     onClick={() => console.log("Button clicked!")}
//                     type="contact-form-button-purple discussion-comment-button"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//       <CustomButton
//         label="View More Answers"
//         onClick={() => console.log("Button clicked!")}
//         type="submit"
//       />
//     </div>
//   );
// };

// export default DiscussionPost;
import React, { useEffect, useState } from "react";
import "./DiscussionPost.css";
import webinarHost1 from "../../assets/images/webinarHost1.jpg";
import profile2 from "../../assets/images/profile2.jpg";
import upArrow from "../../assets/images/arrow-badge.svg";
import downArrow from "../../assets/images/arrow-down-badge.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import { useParams } from "react-router-dom";
import { fetchDiscussionById, postDiscussion } from "../../services/DiscussionForum/DiscussionForum";
import { useSelector, useDispatch } from "react-redux";

const DiscussionPost = () => {
  const { id } = useParams();
  
  
   const dispatch = useDispatch();
   const [source, setSource] = useState(""); // Define state for comment input
   const [visibleCommentInput, setVisibleCommentInput] = useState(null);


  // Redux state
     const {  discussionById, loading, error } = useSelector((state) => state.discussionForum);
   useEffect(() => {
        dispatch(fetchDiscussionById(id));
      }, [dispatch, id]);


      console.log(id);



      const handleCommentSubmit = async () => {
        if (!source.trim()) {
          alert("Comment cannot be empty.");
          return;
        }
      
        try {
          const response = await dispatch(postDiscussion({ id, content: source }));
      
          if (postDiscussion.fulfilled.match(response)) {
            alert("Comment posted successfully!");
            setSource(""); // Clear input after submission
            dispatch(fetchDiscussionById(id)); // Refresh discussion to show new comments
          } else {
            alert("Error posting comment");
          }
        } catch (error) {
          console.error("Error submitting comment:", error);
          alert("An error occurred. Please try again.");
        }
      };
      

  return (
    <div className="main-discussions-container">
      <div className="main-discussion-post-container">
        <div className="learninghub-name-date-container">
          <div>Posted by {discussionById?.user?.firstName || "Unknown"}</div>
          <div>{new Date(discussionById?.publishedAt).toDateString()}</div>
        </div>
        <div className="discussion-post-container">
          <div className="discussion-profile-container">
            <div className="host-img-container">
              <img className="webinar-host-img" src={discussionById?.user?.lastName?discussionById?.user?.lastName:'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930' } alt="host"                   
              onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
              />
            </div>
            <div className="discussions-number-container">
              <img className="up-down-arrow" src={upArrow} />
              <div>24</div>
              <img className="up-down-arrow" src={downArrow} />
            </div>
          </div>

          <div className="discussion-post-description-container">
            <h5>
              {discussionById?.title || "Unknown title"}
            </h5>
            <p className="discussion-post-des">
              {discussionById?.content || "Unknown content"}
            </p>
            <div className="comment-share-button-container">
              <CustomButton
                // label="24 Upvotes"
                label= {`${discussionById?.upVotes} Upvote`}
                onClick={() => console.log("Button clicked!")}
                type="comment-share-button"
              />
              <CustomButton
                // label="21 Comments"
                label= {`${discussionById?.commentsCount} Comments`}
                onClick={() => console.log("Button clicked!")}
                type="comment-share-button"
              />
              <CustomButton
                // label="8 Shares"
                label= {`${discussionById?.sharesCount} Shares`}
                onClick={() => console.log("Button clicked!")}
                type="comment-share-button"
              />
               <CustomButton label="Comment" onClick={() => setVisibleCommentInput('main')} type="contact-form-button-purple discussion-comment-button"/>
            </div>

            {visibleCommentInput === 'main' && (
            <div>
            <input
              className="post-comment-input"
              placeholder="Share your thoughts here..."
              value={source}
              onChange={(e) => setSource(e.target.value)}
            />

            <div className="contact-form-button-container">
              <CustomButton
                label="Cancel"
                // onClick={() => console.log("Button clicked!")}
                onClick={() => setSource("")}
                type="contact-form-button discussion-comment-button"
              />
              <CustomButton
                label="send"
                // onClick={() => console.log("Button clicked!")}
                onClick={handleCommentSubmit} 
                type="contact-form-button-purple discussion-comment-button"
              />
            </div>
            </div>
            )}
          </div>
        </div>

        <h6>12 Answers</h6>
        {discussionById?.commentPosts?.map((data, index) => (
          <div key={index} className="answers-container">
            <div className="learninghub-name-date-container">
              <div>{data?.user?.firstName}</div>
              <div>
                {/* {data?.publishedAt} */}
                {new Date(data?.publishedAt).toDateString()}

              </div>
            </div>

            <div className="discussion-post-container">
              <div className="discussion-profile-container">
                <div className="host-img-container">
                  <img
                    className="webinar-host-img"
                    // src={data.profileImg}
                    src={data?.user?.lastName}
                    alt="host"
                    onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
                  />
                </div>
                <div className="discussions-number-container">
                  <img className="up-down-arrow" src={upArrow} />
                  <div>24</div>
                  <img className="up-down-arrow" src={downArrow} />
                </div>
              </div>

              <div className="discussion-post-description-container">
                {/* <p className="discussion-ans">{data.ans}</p> */}
                <p className="discussion-ans">{data.content}</p>

                <div className="comment-share-button-container">
                  <CustomButton
                    // label="24 Upvotes"
                    label= {`${data?.upVotes} Upvote`}
                    onClick={() => console.log("Button clicked!")}
                    type="comment-share-button"
                  />
                  <CustomButton
                    // label="21 Comments"
                    label= {`${data?.upVotes} Comments`}
                    onClick={() => console.log("Button clicked!")}
                    type="comment-share-button"
                  />
                  <CustomButton
                    // label="8 Shares"
                    label= {`${data?.sharesCount} Shares`}
                    onClick={() => console.log("Button clicked!")}
                    type="comment-share-button"
                  />
                  <CustomButton
                    label="Comment"
                    // onClick={() => console.log("Button clicked!")}
                    onClick={() => setVisibleCommentInput(index)}
                    type="contact-form-button-purple discussion-comment-button"
                  />
                </div>

                {visibleCommentInput === index && (
              <div>
                <input
                  className="post-comment-input"
                  placeholder="Reply to this comment..."
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                />
                <div className="contact-form-button-container">
                <CustomButton label="Cancel" onClick={() => setVisibleCommentInput(null)} type="contact-form-button discussion-comment-button"/>
                <CustomButton label="Send" onClick={handleCommentSubmit} type="contact-form-button-purple discussion-comment-button" />
                </div>
              </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <CustomButton
        label="View More Answers"
        onClick={() => console.log("Button clicked!")}
        type="submit"
      />
    </div>
  );
};

export default DiscussionPost;
