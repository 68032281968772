// Learning Hub Action Types
export const FETCH_LEARNINGHUB_DATA_SUCCESS = "FETCH_LEARNINGHUB_DATA_SUCCESS";
export const FETCH_LEARNINGHUB_DATA_FAILURE = "FETCH_LEARNINGHUB_DATA_FAILURE";
export const FETCH_LEARNING_HUB_SUCCESS = "FETCH_LEARNING_HUB_SUCCESS";
export const FETCH_LEARNING_HUB_FAILURE = "FETCH_LEARNING_HUB_FAILURE";
export const CREATE_LEARNING_HUB_SUCCESS = "CREATE_LEARNING_HUB_SUCCESS";
export const CREATE_LEARNING_HUB_FAILURE = "CREATE_LEARNING_HUB_FAILURE";
export const UPDATE_LEARNING_HUB_SUCCESS = "UPDATE_LEARNING_HUB_SUCCESS";
export const UPDATE_LEARNING_HUB_FAILURE = "UPDATE_LEARNING_HUB_FAILURE";

// learninghubAction.js

export const updateLearningHubSuccess = (updatedData) => ({
  type: "UPDATE_LEARNING_HUB_SUCCESS",
  payload: updatedData,
});

export const updateLearningHubFailure = (error) => ({
  type: "UPDATE_LEARNING_HUB_FAILURE",
  payload: error,
});


// Action creators for Learning Hub data
export const fetchLearningHubDataSuccess = (data) => ({
    type: FETCH_LEARNINGHUB_DATA_SUCCESS,
    payload: data,
  });
  
  export const fetchLearningHubDataFailure = (error) => ({
    type: FETCH_LEARNINGHUB_DATA_FAILURE,
    payload: error,
  });


  // Action creators for Learning Hub data
export const fetchLearningHubSuccess = (data) => ({
  type: FETCH_LEARNING_HUB_SUCCESS,
  payload: data,
});

export const fetchLearningHubFailure = (error) => ({
  type: FETCH_LEARNING_HUB_FAILURE,
  payload: error,
});

export const createLearningHubSuccess = (data) => ({
  type: CREATE_LEARNING_HUB_SUCCESS,
  payload: data,
});

export const createLearningHubFailure = (error) => ({
  type: CREATE_LEARNING_HUB_FAILURE,
  payload: { error },
});