import React, { useState } from "react";
import "./CustomTable.css";
import doubleArrowRight from "../../assets/images/doubleArrowRight.svg";
import singleArrowRight from "../../assets/images/singleArrowRight.svg";
import Pagination from "../../components/Pagination/Pagination";

const CustomTable = ({
  columns,
  data,
  setActiveTab,
  activeTab,
  tabs,
  handleEditClick,
  handleViewClick,
  showActionColumn = true,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPage = 12;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    data && data.length > 0 && data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderBadge = (text, type) => {
    const badgeClass = text.toLowerCase().replace(" ", "-");
    return <span className={`dt-badge ${type}-${badgeClass}`}>{text}</span>;
  };

  const renderCell = (column, value) => {
    if (column.key === "role") {
      const roleStyle = {
        color:
          value === "Applicant"
            ? "#3A37D6"
            : value === "Referrer"
            ? "#9747FF"
            : "inherit",
        fontWeight: "bold",
      };
      return <span style={roleStyle}>{value}</span>;
    }

    switch (column.type) {
      case "status":
        return renderBadge(value, "status");
      case "category":
        return renderBadge(value, "category");
      default:
        return value;
    }
  };

  const renderActionButtons = (id) => (
    <div className="dt-action-buttons">
      <button
        className="dt-action-btn"
        aria-label="Edit"
        onClick={() =>handleEditClick&& handleEditClick(id)}
      >
        <svg
          className="dt-icon edit"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" />
          <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z" />
        </svg>
      </button>
    </div>
  );

  return (
    <div>

      <div className="dt-container">
        <table className="dt-table">
          <thead>
            <tr>
              {columns &&
                columns.length > 0 &&
                columns.map((column, index) => (
                  <th key={index} className="dt-header-cell">
                    {column.label}
                    {column.sortable && <span className="dt-sort-icon">▼</span>}
                  </th>
                ))}
              {showActionColumn && <th className="dt-header-cell"></th>}

              {showActionColumn &&
                showActionColumn.length > 0 &&
                showActionColumn && <th className="dt-header-cell">Action</th>}
            </tr>
          </thead>
          <tbody>
            {currentItems &&
              currentItems.length > 0 &&
              currentItems.map((row, rowIndex) => (
                <tr key={rowIndex} className="dt-row">
                  {columns.map((column, colIndex) => (
                    <td
                      key={colIndex}
                      className="dt-cell"
                      onClick={() => {
                        const clickId = row._id || row.id; // Fallback to _id if id is not present
                        if (column.isClickable && clickId) {
                          handleViewClick(clickId);
                        }
                      }}
                    >
                      {renderCell(column, row[column.key])}
                    </td>
                  ))}
                  {showActionColumn && (
                    <td className="dt-cell">{renderActionButtons(row._id)}</td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPage}
          paginate={paginate}
          singleArrowRight={singleArrowRight}
          doubleArrowRight={doubleArrowRight}
        />
      )}
    </div>
  );
};

export default CustomTable;
