import React from "react";
import "./Home.css";
import Header from "../containers/Home/Header";
import WhoCanUse from "../containers/Home/WhoCanUse";
import Services from "../containers/Home/Services";
import JoinCommunity from "../containers/Home/JoinCommunity";
import SuccessStories from "../containers/Home/SuccessStories";
import Questions from "../containers/Home/Questions";

const Home = () => {
  return (
    <>
      <Header />
        <div style={{background:'#E0E0E0',width:'100vw',display:'flex',justifyContent:'center'}}><WhoCanUse  /></div>
        <br/>
        <br/>
        <br/>
        <div className="main-home-container">
        <Services />
        <SuccessStories />
        </div>
        <div style={{background:'#E0E0E0',width:'100vw',display:'flex',justifyContent:'center'}}> <Questions /></div>
        <div className="main-home-container"> <JoinCommunity /></div>
    </>
  );
};

export default Home;
