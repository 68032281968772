import React, { useState, useEffect } from "react";
import "./LearningHubArticleView.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import learninghubImg1 from "../../assets/images/learninghubImg1.svg";
import articalBackArrow from "../../assets/images/artical-back-arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import learningleftarrow from "../../assets/images/learningHub-articleView-left-arrow.svg";
import learningrightarrow from "../../assets/images/learningHub-articleView-right-arrow.svg";
import { useSelector, useDispatch } from "react-redux";
import { fetchLearningHubById } from "../../services/LearningHub/LearningHub";

const LearningHubArticleView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();

  // Redux state 
  const { learningData, loading, error } = useSelector((state) => state.learninghub);

  useEffect(() => {
    // Dispatch the action to fetch learning hub by ID
    // debugger
    dispatch(fetchLearningHubById(id));
  }, [dispatch, id]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state
  }

  if (error) {
    return <div>Error: {error.message || 'Something went wrong!'}</div>; // Show error message properly
  }

  if (!learningData) {
    return <div>No data found.</div>; // Handle no data state
  }

  // Assuming 'learningData' has the same structure as the API response
  const articleData = {
    img: learningData.images, // This can be updated based on the API response
    name: learningData.author,
    date: new Date(learningData.publishedAt).toLocaleDateString(),
    heading: learningData.title,
    des: learningData.content,
  };

  // Use fallback to empty array if tags are not available
  const tags = (learningData?.tags || []).map((tag) => ({ tag }));

  return (
    <div className="main-articleview-container">
      <div className="articleview-back-button-container">
        <CustomButton
          label={
            <span className="btn-arrow-aliment">
              <img
                src={articalBackArrow}
                alt="back arrow"
                style={{ width: "20px", marginRight: "8px" }}
              />
              Back to Learning Hub
            </span>
          }
          onClick={() => navigate("/learningHub")}
          type="backto-button"
        />
      </div>
      <div className="articleview-img-container">
        <img
          className="articleview-image"
          src={`https://konect.sktgold.com/`+ articleData?.img?. [1] || 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
          onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
          alt="Article"
        />
      </div>
      <div className="articleview-des-container">
        <div className="learninghub-name-date-container">
          <div>{articleData.name}</div>
          <div>{articleData.date}</div>
        </div>
        <h3 className="articleview-heading">{articleData.heading}</h3>
        <p className="articleview-des">{articleData.des}</p>

        <div className="tag-container">
          <p className="articleview-des">Tags</p>
          {tags.map((data, index) => (
            <CustomButton
              key={index}
              label={data.tag}
              onClick={() => console.log("Button clicked!")}
              type="tag-button"
            />
          ))}
        </div>

        <div className="pre-next-article-container">
          <div className="pre-article-container">
            <div className="previous-post">Previous Post</div>
            <div>
              {learningData.previousPost ? (
                <div
                className="clickable-post"
                onClick={() => navigate(`/articleview/${learningData.previousPost._id}`)}
                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                >
                  {/* <i className="fa-solid fa-arrow-left"></i> */}
                  <img
                  className="learningHub-arrow-props"
                  src={learningleftarrow}>
                  </img>
                  <div>{learningData.previousPost.title}</div>
                </div>
              ) : (
                <div>No previous post</div>
              )}
            </div>
          </div>
          <div className="pre-article-container">
            <div className="next-post">Next Post</div>
            <div className="nextPost-place">
              {learningData.nextPost ? (
                <div
                className="clickable-post"
                onClick={() => navigate(`/articleview/${learningData.nextPost._id}`)}
                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                >
                  <div>{learningData.nextPost.title}</div>
                  {/* <i className="fa-solid fa-arrow-right"></i> */}
                  <img
                  className="learningHub-arrow-props"
                  src={learningrightarrow}>
                  </img>
                </div>
              ) : (
                <div>No next post</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default LearningHubArticleView;