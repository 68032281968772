// import React from "react";
// import "./DiscussionFormDetails.css";
// import webinarHost1 from "../../../assets/images/webinarHost1.jpg";
// import profile2 from "../../../assets/images/profile2.jpg";
// import upArrow from "../../../assets/images/arrow-badge.svg";
// import downArrow from "../../../assets/images/arrow-down-badge.svg";
// import CustomButton from "../../../components/CustomButton/CustomButton";
// import { fetchDiscussionById } from "../../../services/DiscussionForum/DiscussionForum";
// import { useSelector, useDispatch } from "react-redux";

// const DiscussionFormPost = () => {


//    // Redux state
//    const {  discussionById, loading, error } = useSelector((state) => state.discussionForum);
//   const postAnswers = [
//     {
//       profileImg: profile2,
//       name: "Posted by Sarah_MarketingPro",
//       date: "27 April 2024",
//       ans: "Congratulations on attending your first industry conference! Here are some strategies that have worked for me:",
//     },
//     {
//       profileImg: profile2,
//       name: "Posted by John_ITSpecialist",
//       date: "27 April 2024",
//       ans: "Networking can be daunting, but it’s incredibly rewarding. Here are a few tips:",
//     },
//     {
//       profileImg: profile2,
//       name: "Posted by Robert_SalesGuru",
//       date: "27 April 2024",
//       ans: "Networking is key to building professional relationships. Here are some strategies:",
//     },
//   ];

//   return (
//     <div className="main-discussions-container">
//       <div className="main-discussion-post-container">
//         <div className="learninghub-name-date-container">
//           <div>Posted by Alex Carl</div>
//           <div>27 April 2024</div>
//         </div>
//         <div className="discussion-post-container">
//           <div className="discussion-profile-container">
//             <div className="host-img-container">
//               <img className="webinar-host-img" src={webinarHost1} alt="host" />
//             </div>
//             <div className="discussions-number-container">
//               <img className="up-down-arrow" src={upArrow} />
//               <div>24</div>
//               <img className="up-down-arrow" src={downArrow} />
//             </div>
//           </div>

//           <div className="discussion-post-description-container">
//             <h5>
//               What Are the Best Strategies for Networking at Industry Events?
//             </h5>
//             <p className="discussion-post-des">
//               I'm attending my first major industry conference next month and I
//               want to make the most of the networking opportunities. What are
//               some effective strategies for connecting with professionals at
//               these events? How can I make a lasting impression and build
//               meaningful relationships? Any advice or personal experiences would
//               be greatly appreciated!
//             </p>

//             <div className="dashboard-comment-share-button-container">
//               <div>
//                 <CustomButton
//                   label="24 Upvotes"
//                   onClick={() => console.log("Button clicked!")}
//                   type="comment-share-button"
//                 />
//                 <CustomButton
//                   label="21 Comments"
//                   onClick={() => console.log("Button clicked!")}
//                   type="comment-share-button"
//                 />
//                 <CustomButton
//                   label="8 Shares"
//                   onClick={() => console.log("Button clicked!")}
//                   type="comment-share-button"
//                 />
//               </div>
//               <CustomButton
//                 label="Restrict"
//                 onClick={() => console.log("Button clicked!")}
//                 type="contact-form-button-red discussion-comment-button"
//               />
//             </div>
//           </div>
//         </div>

//         <h6>12 Answers</h6>
//         {postAnswers.map((data) => (
//           <div className="answers-container">
//             <div className="learninghub-name-date-container">
//               <div>{data.name}</div>
//               <div>{data.date}</div>
//             </div>

//             <div className="discussion-post-container">
//               <div className="discussion-profile-container">
//                 <div className="host-img-container">
//                   <img
//                     className="webinar-host-img"
//                     src={data.profileImg}
//                     alt="host"
//                   />
//                 </div>
//                 <div className="discussions-number-container">
//                   <img className="up-down-arrow" src={upArrow} />
//                   <div>24</div>
//                   <img className="up-down-arrow" src={downArrow} />
//                 </div>
//               </div>

//               <div className="discussion-post-description-container">
//                 <p className="discussion-ans">{data.ans}</p>

//                 <div className="dashboard-comment-share-button-container">
//                   <div>
//                     <CustomButton
//                       label="24 Upvotes"
//                       onClick={() => console.log("Button clicked!")}
//                       type="comment-share-button"
//                     />
//                     <CustomButton
//                       label="21 Comments"
//                       onClick={() => console.log("Button clicked!")}
//                       type="comment-share-button"
//                     />
//                     <CustomButton
//                       label="8 Shares"
//                       onClick={() => console.log("Button clicked!")}
//                       type="comment-share-button"
//                     />
//                   </div>
//                   <CustomButton
//                     label="Restrict"
//                     onClick={() => console.log("Button clicked!")}
//                     type="contact-form-button-red discussion-comment-button"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default DiscussionFormPost;
import React, { useEffect } from "react";
import "./DiscussionFormDetails.css";
import webinarHost1 from "../../../assets/images/webinarHost1.jpg";
import profile2 from "../../../assets/images/profile2.jpg";
import upArrow from "../../../assets/images/arrow-badge.svg";
import downArrow from "../../../assets/images/arrow-down-badge.svg";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { fetchDiscussionById } from "../../../services/DiscussionForum/DiscussionForum";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const DiscussionFormPost = () => {
  const dispatch = useDispatch();
  // const { id } = useParams();
  const params = useParams();
    const id = params.id;
  
  console.log(id);

  useEffect(() => {
      dispatch(fetchDiscussionById(id));
    }, [dispatch, id]);
  
  
   // Redux state
   const {  discussionById, loading, error } = useSelector((state) => state.discussionForum);
  const postAnswers = [
    {
      profileImg: profile2,
      name: "Posted by Sarah_MarketingPro",
      date: "27 April 2024",
      ans: "Congratulations on attending your first industry conference! Here are some strategies that have worked for me:",
    },
    {
      profileImg: profile2,
      name: "Posted by John_ITSpecialist",
      date: "27 April 2024",
      ans: "Networking can be daunting, but it’s incredibly rewarding. Here are a few tips:",
    },
    {
      profileImg: profile2,
      name: "Posted by Robert_SalesGuru",
      date: "27 April 2024",
      ans: "Networking is key to building professional relationships. Here are some strategies:",
    },
  ];

      // useEffect(() => {
      //   if (id) {
      //       dispatch(fetchDiscussionById(id));
      //   }
      // }, [dispatch, id]);

  return (
    <div className="main-discussions-container">
      <div className="main-discussion-post-container">
        <div className="learninghub-name-date-container">
          {/* <div>Posted by Alex Carl</div> */}
          {/* <div>27 April 2024</div> */}
           <div>Posted by {discussionById?.user?.firstName || "Unknown"}</div>
           <div>{new Date(discussionById?.publishedAt).toDateString()}</div>
        </div>
        <div className="discussion-post-container">
          <div className="discussion-profile-container">
            <div className="host-img-container">
              <img className="webinar-host-img" 
              src={ `https://konect.sktgold.com/`+ discussionById?. images [1]|| 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'} 
              alt="host" 
              onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
              />
            </div>
            <div className="discussions-number-container">
              <img className="up-down-arrow" src={upArrow} />
              <div>24</div>
              <img className="up-down-arrow" src={downArrow} />
            </div>
          </div>

          <div className="discussion-post-description-container">
            <h5>
              {/* What Are the Best Strategies for Networking at Industry Events? */}
              {discussionById?.title || "Unknown title"}
            </h5>
            <p className="discussion-post-des">
              {/* I'm attending my first major industry conference next month and I
              want to make the most of the networking opportunities. What are
              some effective strategies for connecting with professionals at
              these events? How can I make a lasting impression and build
              meaningful relationships? Any advice or personal experiences would
              be greatly appreciated! */}
              {discussionById?.content || "Unknown content"}
            </p>

            <div className="dashboard-comment-share-button-container">
              <div>
                <CustomButton
                  // label="24 Upvotes"
                  label= {`${discussionById?.upVotes} Upvote`}
                  onClick={() => console.log("Button clicked!")}
                  type="comment-share-button"
                />
                <CustomButton
                  // label="21 Comments"
                  label= {`${discussionById?.commentsCount} Comments`}
                  onClick={() => console.log("Button clicked!")}
                  type="comment-share-button"
                />
                <CustomButton
                  // label="8 Shares"
                  label= {`${discussionById?.sharesCount} Shares`}
                  onClick={() => console.log("Button clicked!")}
                  type="comment-share-button"
                />
              </div>
              <CustomButton
                label="Restrict"
                onClick={() => console.log("Button clicked!")}
                type="contact-form-button-red discussion-comment-button"
              />
            </div>
          </div>
        </div>

        <h6>12 Answers</h6>
        {discussionById?.commentPosts?.map((data) => (
          <div className="answers-container">
            <div className="learninghub-name-date-container">
              {/* <div>{data.name}</div>
              <div>{data.date}</div> */}
              <div>{data?.user?.firstName}</div>
              <div>{new Date(data?.publishedAt).toDateString()}</div>
            </div>

            <div className="discussion-post-container">
              <div className="discussion-profile-container">
                <div className="host-img-container">
                  <img
                    className="webinar-host-img"
                    // src={data.profileImg}
                    src={ `https://konect.sktgold.com/`+ data?.user?.lastName || 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'} 
                    alt="host"
                    onError={(e) => e.target.src = 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'}
                  />
                </div>
                <div className="discussions-number-container">
                  <img className="up-down-arrow" src={upArrow} />
                  <div>24</div>
                  <img className="up-down-arrow" src={downArrow} />
                </div>
              </div>

              <div className="discussion-post-description-container">
                {/* <p className="discussion-ans">{data.ans}</p> */}
                <p className="discussion-ans">{data.content}</p>

                <div className="dashboard-comment-share-button-container">
                  <div>
                    <CustomButton
                      // label="24 Upvotes"
                      label= {`${data?.upVotes} Upvote`}
                      onClick={() => console.log("Button clicked!")}
                      type="comment-share-button"
                    />
                    <CustomButton
                      // label="21 Comments"
                      label= {`${data?.upVotes} Comments`}
                      onClick={() => console.log("Button clicked!")}
                      type="comment-share-button"
                    />
                    <CustomButton
                      // label="8 Shares"
                      label= {`${data?.sharesCount} Shares`}
                      onClick={() => console.log("Button clicked!")}
                      type="comment-share-button"
                    />
                  </div>
                  <CustomButton
                    label="Restrict"
                    onClick={() => console.log("Button clicked!")}
                    type="contact-form-button-red discussion-comment-button"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DiscussionFormPost;

