import { FETCH_DISCUSSIONS_SUCCESS, FETCH_DISCUSSIONS_FAILURE } from "../actions/discussionForumAction";
import { FETCH_TAGS_SUCCESS, FETCH_TAGS_FAILURE } from "../actions/discussionForumAction";
import { POST_DISCUSSION_SUCCESS, POST_DISCUSSION_FAILURE } from "../actions/discussionForumAction";
import { FETCH_DISCUSSION_By_Id_SUCCESS, FETCH_DISCUSSION_By_Id_FAILURE } from "../actions/discussionForumAction";

const initialState = {
    discussions: [],
    tags:[],
    discussionpost: null,
    error: null,
};

const discussionForumReducer = (state = initialState, action) => {
    switch (action.type) {

        case FETCH_DISCUSSION_By_Id_SUCCESS:
            return {
                ...state,
                discussionById: action.payload,
                error: null,
            };
        case FETCH_DISCUSSION_By_Id_FAILURE:
            return {
                ...state,
                discussionById: null,
                error: action.payload,
            };
        case FETCH_DISCUSSIONS_SUCCESS:
            return {
                ...state,
                discussions: action.payload,
                error: null,
            };
        case FETCH_DISCUSSIONS_FAILURE:
            return {
                ...state,
                discussions: [],
                error: action.payload,
            };

        case FETCH_TAGS_SUCCESS:
            return {
                ...state,
                tags: action.payload,
                error: null,
            };
        case FETCH_TAGS_FAILURE:
            return {
                ...state,
                tags: [],
                error: action.payload,
            };

        case POST_DISCUSSION_SUCCESS:
            return {
                ...state,
                discussionpost: action.payload,
                error: null,
            };
        case POST_DISCUSSION_FAILURE:
            return {
                ...state,
                discussionpost: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default discussionForumReducer;
