import React, { useEffect, useState, useRef } from "react";
import "./ChatContainer.css";
import people2 from "../../../../assets/images/people2.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  openChat,
  sendMessage,
} from "../../../../services/jobSeeker/jobSeekerService";
import people1 from "../../../../assets/images/people1.jpg";
import people3 from "../../../../assets/images/people3.jpg";
import requestIcon from "../../../../assets/images/adminLayout/Dashboard/Icons/requestIcon.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ChatContainer = ({ job }) => {
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [filePreview, setFilePreview] = useState("");
  const fileInputRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("user"));
  const userRole = userData.user.userRole;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleViewRequestBtn = (id) => {
    if (id) {
      navigate(`/admin_dashboard/job_referrals/${id}`);
    }
  };
  function isImage(filePath) {
    const validImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'webp'];
    const fileExtension = filePath.split('.').pop().toLowerCase(); // Get the file extension
    return validImageExtensions.includes(fileExtension);
  }
  
  const filePath = "uploads/1737441800039-739474751-Satharuban_Cover_Letter (1).pdf";
  console.log(isImage(filePath)); // Output: false
  
  useEffect(() => {
    const request_id = job?.requestDetails?._id;
    const queryParams = {
      chatType: job?.chatType,
      requestId: request_id,
    };

    if (request_id) {
      dispatch(openChat(queryParams));
    }
  }, [dispatch, job]);

  const chat = useSelector((state) => state.jobSeeker.selectedChat || {});

  const [messages, setMessages] = useState(chat?.messages || []);

  useEffect(() => {
    setMessages(chat?.messages || []);
  }, [chat]);

  const handleSendMessage = async () => {
    if (!message && !attachment) return;

    const payload = {
      chatId: chat._id,
      contentType: attachment ? "file" : message ? "text" : null,
      content: attachment || message,
    };

    try {
      const response = await dispatch(sendMessage(payload));
      if (response?.status === "success") {
        setMessage("");
        setAttachment(null);
        setFilePreview("");
        if (fileInputRef.current) fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setAttachment(selectedFile);
      setFilePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleRemoveFilePreview = () => {
    setAttachment(null);
    setFilePreview("");
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  return (
    <>
      {chat && (
        <div className="jri-chat-header">
          <div className="jri-chat-users">
            <div className="jri-user">
              <img
                src={chat.participants?.jobSeeker?.picture || people1}
                alt="User avatar"
                className="jri-user-avatar"
              />
              <div className="jri-user-info">
                <span className="jri-user-name">
                  {chat.participants?.jobSeeker?.name}
                </span>
                <span className="jri-user-role">(User)</span>
              </div>
            </div>

            {chat.chatType === "jobReferral" && (
              <div className="jri-user">
                <img
                  src={chat.participants?.referrer?.picture || people3}
                  alt="Referrer avatar"
                  className="jri-user-avatar"
                  onError={(e) => e.target.src = 'https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o='}
                />
                <div className="jri-user-info">
                  <span className="jri-user-name">
                    {chat.participants?.referrer?.firstName}
                  </span>
                  <span className="jri-user-role">(Referrer)</span>
                </div>
              </div>
            )}

            {/* <div className="jri-chat-actions">
              <span className="jri-status-badge">
                {chat.requestDetails?.currentStatus}
              </span>
              {/* {chat.chatType === "jobReferral" && (
                <button
                  className="jri-view-btn"
                  onClick={() =>
                    handleViewRequestBtn(chat?.requestDetails?._id)
                  }
                >
                  <img src={requestIcon} alt="Request icon"  />
                  View Request
                </button>
              )}
            </div> */}
          </div>
        </div>
      )}

      <div className="chat-app-container">
        <div className="chat-app-messages">
          {messages?.map((message) => {
            const isUser = message.sender === chat.participants?.jobSeeker?._id;
            const avatar = isUser
              ? chat.participants?.jobSeeker?.picture || people2 
              : chat.participants?.referrer?.picture || people2;
            return (
              <div
                key={message._id}
                className={`chat-app-message ${
                  message.isUser ? "sent" : "received"
                }`}
              >
                {isUser && (
                  <div className="chat-avatar-container">
                    <img
                      src={avatar}
                      alt="Avatar"
                      className="jri-avatar"
                    />
                  </div>
                )}
                <div className="chat-app-message-content">
                  <div>{message.content?.type=='text'? message.content?.value:isImage(message.content?.value)?<img src={`https://konect.sktgold.com/${message.content?.value}`}/>: (<a href={`https://konect.sktgold.com/${message.content?.value}`} target="_blank" rel="noopener noreferrer">File</a>)}</div>
                  <span className="chat-app-message-time">
                    {moment(message.timestamp).format("MM/DD/YYYY")}
                  </span>
                </div>
                {!isUser && (
                  <div className="chat-avatar-container">
                    <img
                      src={avatar || 'https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o='}
                      alt="Avatar"
                      className="jri-avatar"
                      onError={(e) => e.target.src = 'https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o='}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {!(chat.chatType === "jobReferral" && userRole === "admin") && (
          <div className="chat-app-input-container">
            {filePreview && (
              <div className="file-preview-container">
                <p>File Preview:</p>
                <a href={`https://konect.sktgold.com/${attachment?.name}`} target="_blank" rel="noopener noreferrer">
                  {attachment?.name}
                </a> 
                {attachment?.type === "application/pdf" ? (
                    <iframe src={filePreview} width="50%" height="150px"></iframe>
                  ) : (
                    <img src={filePreview} alt="preview" style={{ maxWidth: "30%" }} />
                  )}

                <button onClick={handleRemoveFilePreview}>Remove</button>
              </div>
            )}

            <div className="chat-app-input-wrapper">
              <input
                type="text"
                placeholder="Type a message..."
                className="chat-app-text-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleSendMessage();
                }}
              />
              <label className="chat-app-attachment-btn">
                <span className="chat-app-icon">📎</span>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </label>

              <button className="chat-app-send-btn" onClick={handleSendMessage}>
                <span className="chat-app-icon">➤</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatContainer;
