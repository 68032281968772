import React, { useState, useEffect } from "react";
import "./ChatManager.css";
import TitlePath from "../../../components/TitlePath/TitlePath";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import ChatManagerSideBar from "../../../containers/AdminLayout/ChatManager/ChatManagerSideBar/ChatManagerSideBar";
import ChatContainer from "../../../containers/AdminLayout/ChatManager/ChatContainer/ChatContainer";
import { fetchAllChat } from "../../../services/jobSeeker/jobSeekerService";
const ChatManager = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Job Referrals");
  const [selectedChat, setselectedChat] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredChats, setFilteredChats] = useState([]);

  useEffect(() => {
    dispatch(fetchAllChat());
  }, [dispatch]);

  const chats = useSelector((state) => state.jobSeeker.chats || []);

  useEffect(() => {
    if (chats.length > 0) {
      handleTabChange(activeTab);
    }
  }, [chats, activeTab]);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    setselectedChat(null);
    setSearchQuery("");

    let filteredChats = [];

    if (tabName === "Job Referrals") {
      filteredChats = chats.filter((chat) => chat.chatType === "jobReferral");
    } else if (tabName === "Resume Review") {
      filteredChats = chats.filter((chat) => chat.chatType === "resumeReview");
    }

    setFilteredChats(filteredChats);
    if (filteredChats.length > 0) {
      setselectedChat(filteredChats[0]);
    }
  };

  const handleJobSelect = (job) => {
    setselectedChat(job);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const tabs = [{ name: "Job Referrals" }, { name: "Resume Review" }];

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const titlePath = {
    exactPath: (
      <span style={{ cursor: "pointer" }} onClick={handleBack}>
        Chat_Managment
      </span>
    ),
    paths: [{ label: "Dashboard >", onClick: handleBack }],
  };

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="chat-container">
        <div className="jra-header">
          <div className="jra-header-title">
            <img src={arrowBack} className="jra-header-title-icon" />
            <h4>Chat</h4>
          </div>
        </div>
      </div>
      <div className="jri-container">
        <div className="tabs">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`chat-tab ${activeTab === tab.name ? "active" : ""}`}
              onClick={() => handleTabChange(tab.name)}
            >
              {tab.name}
            </button>
          ))}
        </div>

        <div className="jri-content">
          <div className="jri-sidebar">
            <div className="jri-search-container">
              <input
                type="text"
                placeholder="Search"
                className="jri-search-input"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>

            <ChatManagerSideBar
              chats={filteredChats}
              onChatSelect={handleJobSelect}
            />
          </div>

          <div className="jri-chat-container">
            {selectedChat && <ChatContainer job={selectedChat} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatManager;
