// import React, { useState, useRef } from "react";
// import "./CreateNewDiscussion.css";
// import CustomButton from "../../components/CustomButton/CustomButton";
// import ItalicI from "../../assets/images/Italic-i.svg";
// import BoldIcon from "../../assets/images/Bold-icon.svg";
// import UnderLine from "../../assets/images/UnderLine.svg";
// import SIcon from "../../assets/images/S-icon.svg";
// import SpaceIcon from "../../assets/images/Space-icon.svg";
// import Dot from "../../assets/images/dot-alimnt.svg";
// import Num from "../../assets/images/Number-aliment.svg";
// import linkIcon from "../../assets/images/Link-icon.svg";
// import IMGUpload from "../../assets/images/Img-uploade-icon.svg";

// import slace from "../../assets/images/slace-icon.svg";
// import cotation from "../../assets/images/cotation-icon.svg";
// import line from "../../assets/images/line-icon.svg";

// const CreateNewDiscussion = () => {
//   return (
//     <div className="create-post-container">
//       <h4 className="create-post-title">
//         Create Post - Start a New Discussion
//       </h4>
//       <form className="create-post-form">
//         <div className="form-group">
//           <div className="discussion-input-label">Discussion Title</div>
//           <input
//             type="text"
//             id="title"
//             maxLength="300"
//             placeholder="Enter your discussion title here"
//             className="input-title"
//           />
//           <div className="character-count">0/300</div>
//         </div>
//         <div className="form-group">
//           <div className="discussion-input-label">Content</div>
//           <textarea
//             id="content"
//             placeholder="What are your thoughts?"
//             className="input-content"
//           ></textarea>
//           <div className="toolbar">
//             <button type="button">
//               <strong>
//                 <img className="word-icons" src={BoldIcon}></img>
//               </strong>
//             </button>
//             <button type="button">
//               <em>
//                 <img className="word-icons" src={ItalicI}></img>
//               </em>
//             </button>
//             <button type="button">
//               <u>
//                 <img className="word-icons" src={UnderLine}></img>
//               </u>
//             </button>
//             <button>
//               <img className="word-icons" src={SIcon}></img>
//             </button>
//             <button type="button">
//               {<img className="word-icons" src={SpaceIcon}></img>}
//             </button>
//             <select className="font-select">
//               <option value="normal">Normal text</option>
//             </select>
//             <div className="Number-dot-aliment">
//               <div>
//                 <img className="dot" src={Dot}></img>
//               </div>
//               <div>
//                 <img className="dot" src={Num}></img>
//               </div>
//             </div>

//             <button type="button">
//               <img className="word-icons" src={linkIcon}></img>
//             </button>
//             <button>
//               <img className="word-icons" src={IMGUpload}></img>
//             </button>
//             <button type="button">
//               <img className="word-icons" src={slace}></img>
//             </button>
//             <button type="button">
//               <img className="word-icons" src={cotation}></img>
//             </button>
//             <button type="button">
//               <img className="word-icons" src={line}></img>
//             </button>
//           </div>
//         </div>
//         <div className="form-group">
//           <div className="discussion-input-label">Tags</div>
//           <input
//             type="text"
//             id="tags"
//             placeholder="Add tags..."
//             className="input-tags"
//           />
//         </div>
//         <div className="contact-form-button-container">
//           <CustomButton
//             label="Cancel"
//             onClick={() => console.log("Button clicked!")}
//             type="contact-form-button"
//           />
//           <CustomButton
//             label="Publish"
//             onClick={() => console.log("Button clicked!")}
//             type="contact-form-button-purple"
//           />
//         </div>
//       </form>
//     </div>
//   );
// };

// export default CreateNewDiscussion;

import React, { useEffect, useState,useRef } from "react";
import "./CreateNewDiscussion.css";
import CustomButton from "../../components/CustomButton/CustomButton";
import ItalicI from "../../assets/images/Italic-i.svg";
import BoldIcon from "../../assets/images/Bold-icon.svg";
import UnderLine from "../../assets/images/UnderLine.svg";
import SIcon from "../../assets/images/S-icon.svg";
import SpaceIcon from "../../assets/images/Space-icon.svg";
import Dot from "../../assets/images/dot-alimnt.svg";
import Num from "../../assets/images/Number-aliment.svg";
import linkIcon from "../../assets/images/Link-icon.svg";
import IMGUpload from "../../assets/images/Img-uploade-icon.svg";

import slace from "../../assets/images/slace-icon.svg";
import cotation from "../../assets/images/cotation-icon.svg";
import line from "../../assets/images/line-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { postDiscussion } from "../../services/DiscussionForum/DiscussionForum";



const CreateNewDiscussion = () => {

  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState("");
  const editorRef = useRef(null);
  const [activeFormats, setActiveFormats] = useState({
    bold: false,
    italic: false,
    underline: false,
    insertOrderedList: false,
    insertUnorderedList: false,
  });


  const formatText = (command) => {
    document.execCommand(command, false, null);
    editorRef.current?.focus();
    updateActiveFormats(); // After formatting, update active formats
  };


  const setFontSize = (size) => {
    editorRef.current.style.fontSize = size;
    editorRef.current.focus();
  };


  const updateActiveFormats = () => {
    setActiveFormats({
      bold: document.queryCommandState("bold"),
      italic: document.queryCommandState("italic"),
      underline: document.queryCommandState("underline"),
      insertOrderedList: document.queryCommandState("insertOrderedList"),
      insertUnorderedList: document.queryCommandState("insertUnorderedList"),
    });
  };

   // Redux state
   const {  discussionpost, loading, error } = useSelector((state) => state.discussionForum);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formattedTags = tags.split(",").map(tag => tag.trim()); // Convert tags to an array
    
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("tags", JSON.stringify(formattedTags)); // Convert array to JSON string
  
    const response = await dispatch(postDiscussion(formData));
    if (response.status === "success") {
      alert("Discussion posted successfully!");
      setTitle("");
      setContent("");
      setTags(""); 
    } else {
      alert("Error posting discussion: " + response.error);
    }
  };


  return (
    <div className="create-post-container">
      <h4 className="create-post-title">
        Create Post - Start a New Discussion
      </h4>
      <form className="create-post-form" 
      // onSubmit={handleSubmit}
      >
        <div className="form-group">
          <div className="discussion-input-label">Discussion Title</div>
          <input
            type="text"
            id="title"
            maxLength="300"
            placeholder="Enter your discussion title here"
            className="input-title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <div className="character-count">0/300</div>
        </div>
        <div className="form-group">
          <div className="discussion-input-label">Content</div>
          <div
            id="editor"
            //  id="content"
            className="input-content"
            contentEditable="true"
            ref={editorRef}
            onInput={(e) => setContent(e.currentTarget.innerHTML)}
          ></div>

          <div className="toolbar">
            <button type="button"  className={`bold-btn ${activeFormats.bold ? "active" : ""}`}
            onClick={() => formatText("bold")}>
              <strong>
                <img className="word-icons" src={BoldIcon}></img>
              </strong>
            </button>
            <button type="button" className={`bold-btn ${activeFormats.italic ? "active" : ""}`}
            onClick={() => formatText("italic")}>
              <em>
                <img className="word-icons" src={ItalicI}></img>
              </em>
            </button>
            <button type="button" className={`bold-btn ${activeFormats.underline ? "active" : ""}`}
            onClick={() => formatText("underline")}>
              <u>
                <img className="word-icons" src={UnderLine}></img>
              </u>
            </button>
            <button type="button">
              <img className="word-icons" src={SIcon}></img>
            </button>
            <button type="button">
              {<img className="word-icons" src={SpaceIcon}></img>}
            </button>
            <select className="font-select" onChange={(e) => setFontSize(e.target.value)}>
            <option value="">Normal text</option>
            <option value="small">Small</option>
            <option value="medium">Normal</option>
            <option value="large">Large</option>
            <option value="x-large">Extra Large</option>
            </select>
            <div className="Number-dot-aliment">
              <button type="button" className={`bold-btn ${activeFormats.insertUnorderedList ? "active" : ""}`}
                onClick={() => formatText("insertUnorderedList")}>
                <img className="dot" src={Dot}></img>
              </button>
              <button type="button" className={`bold-btn ${activeFormats.insertOrderedList ? "active" : ""}`}
               onClick={() => formatText("insertOrderedList")}>
                <img className="dot" src={Num}></img>
              </button>
            </div>

            <button type="button">
              <img className="word-icons" src={linkIcon}></img>
            </button>
            <button>
              <img className="word-icons" src={IMGUpload}></img>
            </button>
            <button type="button">
              <img className="word-icons" src={slace}></img>
            </button>
            <button type="button">
              <img className="word-icons" src={cotation}></img>
            </button>
            <button type="button">
              <img className="word-icons" src={line}></img>
            </button>
          </div>
        </div>
        <div className="form-group">
          <div className="discussion-input-label">Tags</div>
          <input
            type="text"
            id="tags"
            placeholder="Add tags..."
            className="input-tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </div>
        <div className="contact-form-button-container">
          <CustomButton
            label="Cancel"
            onClick={() => console.log("Button clicked!")}
            type="contact-form-button"
          />
          <CustomButton
            label="Publish"
            // onClick={() => console.log("Button clicked!")}
            onClick={handleSubmit}
            type="contact-form-button-purple"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateNewDiscussion;

