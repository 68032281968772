import React, { useState, useEffect } from "react";
import "./DashboardLearningHub.css";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../components/TitlePath/TitlePath";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { fetchLearningHubData } from "../../../services/admin/adminService";
import { useSelector, useDispatch } from "react-redux";
import doubleArrowRight from "../../../assets/images/doubleArrowRight.svg";
import singleArrowRight from "../../../assets/images/singleArrowRight.svg";
import Pagination from "../../../components/Pagination/Pagination";
import DashboardHeading from "../../../components/DashboardHeading/DashboardHeading";
import CustomButton from "../../../components/CustomButton/CustomButton";
import buttonPlusicon from "../../../assets/images/adminLayout/Dashboard/Icons/create-button-plus-icon.svg"

const DashboardLearningHub = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state
  const { learningHubData, loading, error } = useSelector((state) => state.admin);
  const data = learningHubData || []; // Fallback to empty array if no data

  const [activeTab, setActiveTab] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of records per page

   // Compute total pages
   const totalPages = Math.ceil(data.length / itemsPerPage);

  // Slice data for current page
  const currentData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  // Map the API data to the format used in the table
  const mappedData = currentData.map(item => ({
    id: item._id, 
    contentId: item.index,
    title: item.title,
    category: item.category.charAt(0).toUpperCase() + item.category.slice(1),
    author: item.author,
    datePublished: new Date(item.publishedAt).toLocaleDateString(),
    status: item.status.charAt(0).toUpperCase() + item.status.slice(1),
  }));

  const tabs = [
    { name: "All", count: 84 },
    { name: "Article", count: 39 },
    { name: "Webinar", count: 33 },
    { name: "Published", count: 33 },
    { name: "Draft", count: 51 },
    { name: "Restricted", count: 21 },
  ];

  const columns = [
    { key: "contentId", label: "Content ID" , isClickable: true },
    { key: "title", label: "Title",  isClickable: true },
    { key: "category", label: "Category", type: "category", sortable: true , isClickable: true },
    { key: "author", label: "Author" , isClickable: true },
    { key: "datePublished", label: "Date Published", isClickable: true  },
    { key: "status", label: "Status", type: "status", sortable: true, isClickable: true  },
  ];
  

  // Fetch data when the component mounts
  useEffect(() => {
    dispatch(fetchLearningHubData());
  }, [dispatch]);

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const titlePath = {
    exactPath: (
      <span style={{ cursor: "pointer" }} onClick={handleBack}>
        Learning_Hub
      </span>
    ),
    paths: [
      { label: "Dashboard > ", onClick: handleBack },
    ],
  };

  const headingDes = {
    heading: "Learning Hub",
    des: "Oversee content and user progress to ensure a seamless and effective learning experience.",
  };

  


  console.log("Mapped Data:", mappedData);

  

  const handleViewClick = (id) => {
    navigate(`/admin_dashboard/create_post/${id}`);
    console.log("ID value:",id);
    
  };
  

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath
          exactPath={titlePath.exactPath}
          paths={titlePath.paths}
          showNotification={true}
        />
         <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
        <div className="sub-setting-page-container blog-post-table-container">
          <div className="dashboard-table-heading-learninghub">
            <h4>Overview</h4> 
            <div className="create-button-place">
            <CustomButton
              label={
                <span className="get-button-aliment"
                // onClick={() => navigate("/admin_dashboard/create_post/${id}")}>
                onClick={() => {
                  navigate("/admin_dashboard/create_post");
                  window.location.reload(); // Refresh the page after navigation
                }}>
                  Create Content
                  <img
                    src={buttonPlusicon}
                    alt="arrow"
                    className="get-button-icon"
                  />
                  
                </span>
              }
              type="createContent-button"
            />
            </div>
            
          </div>
          {/* Pass the mapped data to CustomTable */}
          <CustomTable
            columns={columns}
            data={mappedData} // Pass the formatted data
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            handleViewClick={handleViewClick}
            handleEditClick={handleViewClick}
          />
        </div>

         {/* Pagination Component */}
         <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          paginate={handlePageChange}
          singleArrowRight={singleArrowRight}
          doubleArrowRight={doubleArrowRight}
        />
      </div>
    </div>
  );
};

export default DashboardLearningHub;
