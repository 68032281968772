import React, { useEffect, useState } from "react";
import "./DiscussionForumFilter.css";
import searchIcon from "../../assets/images/searchIcon.svg";
import clockIcon from "../../assets/images/clockIcon.svg";
import fireIcon from "../../assets/images/fireIcon.svg";
import { fetchTags,  fetchDiscussions } from "../../services/DiscussionForum/DiscussionForum";
import { useSelector, useDispatch } from "react-redux";

const DiscussionForumFilter = () => {
  const [selectedRadio, setSelectedRadio] = useState("API");
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");

  // Redux state
     const { tags, loading, error } = useSelector((state) => state.discussionForum);

     const { discussions } = useSelector((state) => state.discussionForum);
console.log("Discussions:", discussions); // Check if filtered discussions appear


     useEffect(() => {
      dispatch(fetchTags());
     }, [dispatch]); 

      // Handle search input change
      const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        console.log("Search Query:", event.target.value);
      };

      // Fetch discussions when searchQuery changes (Debounced)
        useEffect(() => {
          const delayDebounceFn = setTimeout(() => {
            console.log("Fetching discussions with query:", searchQuery);
            dispatch(
              fetchDiscussions({
                page: 1,
                limit: 10,
                search: searchQuery,
                isDashboard: true,
                status: "published",
                tag: selectedRadio,
                type: "recent",
                isRecentlyVisited: true,
              })
            );
          }, 500);
        
          return () => clearTimeout(delayDebounceFn);
        }, [searchQuery, selectedRadio, dispatch]);

  const handleChange = (event) => {
    setSelectedRadio(event.target.value);
  };
  return (
    <div className="discussion-fourm-filter-container">
      <div className="help-center-search-container">
        <div className="discussion-forum-search">
          <img
            className="search-icon-help-center"
            src={searchIcon}
            alt="search icon"
          />
          <input
            className="discussion-forum-search-input"
            placeholder="What do you want to ask?"
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
      </div>
      <div>
        <h4>Konect Community Forum</h4>
        <p className="articleview-des">
          Welcome to the heart of the Konect community! This forum is your go-to
          place for engaging with fellow job seekers and industry professionals.
          Whether you’re looking for advice, sharing your experiences, or
          seeking inspiration, you’ll find a supportive network here.
        </p>
        <div className="discussion-button-container">
          <button className="discussion-button">
            {" "}
            <img className="discussion-button-icon" src={clockIcon} />
            Most Recent
          </button>
          <button className="discussion-button">
            <img className="discussion-button-icon-fire" src={fireIcon} />
            Most Popular
          </button>
        </div>
      </div>

      <div className="questions-filter-container">
        <div>Category</div>
        <div className="filter-radio-button-container">
     
           {loading ? (
            <p>Loading tags...</p>
          ) : error ? (
            <p>Error fetching tags</p>
          ) : (
            tags.map((tag) => (
              <label key={tag} style={{ color: selectedRadio === tag ? "#1AD079" : "inherit" }}>
                <input type="radio" name="radioGroup" value={tag} onChange={(e) => setSelectedRadio(e.target.value)} checked={selectedRadio === tag}/>
                {tag}
              </label>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default DiscussionForumFilter;
