import { showToastMessage } from "../../redux/actions/toastActions";
import { startLoading, stopLoading } from "../../redux/actions/spinnerActions";
import {
  createJobReferralRequest,
  createResumeReviewRequest,
  fetchDashboardSuccess,
  fetchDashboarsdFailure,
  getJobReferralById,
  getJobReferrals,
  getResumeReview,
  getResumeReviewById,
  updateAdminFeedback,
  updateResumeReviewFeedback,
  fetchUsersSuccess,
  fetchUsersFailure,
  fetchUserSuccess,
  fetchUserFailure,
  updateVerificationStatusSuccess,
  updateVerificationStatusFailure,

  fetchLearningHubDataSuccess,
  fetchLearningHubDataFailure,
} from "../../redux/actions/adminAction";
import { apiRequest } from "../../utils/apiRequest";



// Api Service for fetching learning hub data
export const fetchLearningHubData = (queryParams = {}) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await apiRequest(
        `/api/learning-hub/admin${query ? `?${query}` : ""}`,
        {},
        false,
        dispatch
      );

      console.log("Fetched Learning Hub Data:", response.data);
      dispatch(fetchLearningHubDataSuccess(response.data));
      dispatch(showToastMessage("Learning hub data fetched successfully!", "success"));
    } catch (error) {
      console.error("Failed to fetch learning hub data:", error);
      dispatch(fetchLearningHubDataFailure(error));
      handleApiError(dispatch, error, "Failed to fetch learning hub data.");
    } finally {
      dispatch(stopLoading());
    }
  };
};



// User Profile
export const updateVerificationStatus = (id, status) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await apiRequest(
        `/api/admin/referrers/${id}/update-verification-status`,
        {
          method: "POST",
          // body: JSON.stringify({ status }),
          body: JSON.stringify({ newStatus: status }),
        },
        true,
        dispatch,
      );
      dispatch(updateVerificationStatusSuccess(response.data));
      dispatch(
        showToastMessage(
          "Verification status updated successfully!",
          "success",
        ),
      );
    } catch (error) {
      dispatch(updateVerificationStatusFailure(error));
      showToastMessage("Failed to update verification status.", "error");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Helper function to dispatch error messages
const handleApiError = (dispatch, error, errorMessage) => {
  console.error(errorMessage, error);
  dispatch(showToastMessage(errorMessage, "error"));
};

// Fetch  users by id
export const fetchUserById = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const response = await apiRequest(
        `/api/admin/users/${id}`,
        {},
        false,
        dispatch,
      );
      console.log("User Data:", response.data);
      dispatch(fetchUserSuccess(response.data));
    } catch (error) {
      console.error("Failed to fetch user:", error);
      dispatch(fetchUserFailure(error));
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Fetch all users
export const fetchAllUsers = (queryParams = {}) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await apiRequest(
        `/api/admin/users${query ? `?${query}` : ""}`,
        {},
        false,
        dispatch,
      );

      console.log("Fetched Users:", response.data);
      dispatch(fetchUsersSuccess(response.data));
      dispatch(showToastMessage("Users fetched successfully!", "success"));
    } catch (error) {
      console.error("Failed to fetch users:", error);
      dispatch(fetchUsersFailure(error));
      handleApiError(dispatch, error, "Failed to fetch users.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Fetch job seeker list
export const fetchDashboard = (queryParams = {}) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const query = new URLSearchParams(queryParams).toString();
      const response = await apiRequest(
        `/api/job-referral/job-seeker/dashboard`,
        {},
        false,
        dispatch,
      );
      //pass response not response.data
      console.log(response);

      dispatch(fetchDashboardSuccess(response.data));
      // dispatch(showToastMessage("Admin fetched successfully!", "success"));
    } catch (error) {
      dispatch(fetchDashboarsdFailure(error));
      handleApiError(dispatch, error, "Failed to fetch Admin.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get a specific job referral by ID
export const getJobReferral = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    console.log("Fetching job referral with ID:", id);
    try {
      const response = await apiRequest(
        `/api/job-referral/job-seeker/${id}`,
        {},
        false,
        dispatch,
      );
      console.log("API Response:", response.data);
      dispatch(getJobReferralById(response.data));
      // dispatch(showToastMessage("Job referral retrieved successfully!", "success"));
    } catch (error) {
      console.error("API Error:", error);
      handleApiError(dispatch, error, "Failed to retrieve job referral.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Update job seeker by ID
export const updateFeedback = (id, adminFeedback) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const updatedAdmin = await apiRequest(
        `/api/job-referral/job-seeker/${id}/feedback`,
        {
          method: "PUT",
          body: JSON.stringify(adminFeedback),
        },
        true,
        dispatch,
      );
      dispatch(updateAdminFeedback(updatedAdmin));
      dispatch(
        showToastMessage("Jobseeker feedback updated successfully!", "success"),
      );
    } catch (error) {
      handleApiError(dispatch, error, "Failed to update jobseeker feedback.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get job referrals
export const fetchJobReferrals = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const jobReferralData = await apiRequest(
        `/api/job-referral/job-seeker`,
        {},
        false,
        dispatch,
      );
      console.log("Job Referral Data :", jobReferralData);

      dispatch(getJobReferrals(jobReferralData.data || []));
      // dispatch(showToastMessage("Job Referrals retrieved successfully!", "success"));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to retrieve job referrals.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Add new job referral
export const addJobReferralRequest = (jobReferralData) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();

      Object.keys(jobReferralData).forEach((key) => {
        formData.append(key, jobReferralData[key]);
      });

      const response = await apiRequest(
        "/api/job-referral/job-seeker",
        {
          method: "POST",
          body: formData,
        },
        false,
        dispatch,
      );

      console.log(response.data);
      dispatch(createJobReferralRequest(response.data));
      dispatch(showToastMessage("Job Referral added successfully!", "success"));
      return { status: "success", data: response };
    } catch (error) {
      handleApiError(dispatch, error, "Failed to add job referral.");
      return { status: "error", error };
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get job referrals
export const fetchResumeReview = () => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const resumeReviewData = await apiRequest(
        `/api/resume-review/job-seeker`,
        {},
        false,
        dispatch,
      );
      console.log("Resume Review Data :", resumeReviewData);

      dispatch(getResumeReview(resumeReviewData.data || []));
      // dispatch(showToastMessage("Resume Review data retrieved successfully!", "success"));
    } catch (error) {
      handleApiError(dispatch, error, "Failed to retrieve resume review");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Add new resume request
export const addResumeReviewRequest = (jobReferralData) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();

      Object.keys(jobReferralData).forEach((key) => {
        formData.append(key, jobReferralData[key]);
      });

      const response = await apiRequest(
        "/api/resume-review/job-seeker",
        {
          method: "POST",
          body: formData,
        },
        false,
        dispatch,
      );

      console.log(response.data);
      dispatch(createResumeReviewRequest(response.data));
      dispatch(
        showToastMessage("Resume Review added successfully!", "success"),
      );
      return { status: "success", data: response };
    } catch (error) {
      handleApiError(dispatch, error, "Failed to add resume review");
      return { status: "error", error };
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Get a specific job referral by ID
export const getResumeReviewData = (id) => {
  return async (dispatch) => {
    dispatch(startLoading());
    console.log("Fetching resume  review with ID:", id);
    try {
      const response = await apiRequest(
        `/api/resume-review/job-seeker/${id}`,
        {},
        false,
        dispatch,
      );
      console.log("API Response:", response.data);
      dispatch(getResumeReviewById(response.data));
      dispatch(
        showToastMessage("Resume Review retrieved successfully!", "success"),
      );
    } catch (error) {
      console.error("API Error:", error);
      handleApiError(dispatch, error, "Failed to retrieve resume review.");
    } finally {
      dispatch(stopLoading());
    }
  };
};

// Update resume review by ID
export const updateResumeReview = (id, resumeReviewFeedback) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      const updatedResumeReview = await apiRequest(
        `/api/resume-review/job-seeker/${id}/feedback`,
        {
          method: "PUT",
          body: JSON.stringify(resumeReviewFeedback),
        },
        true,
        dispatch,
      );
      dispatch(updateResumeReviewFeedback(updatedResumeReview));
      dispatch(
        showToastMessage(
          "Resume Review feedback updated successfully!",
          "success",
        ),
      );
    } catch (error) {
      handleApiError(
        dispatch,
        error,
        "Failed to update resume review feedback.",
      );
    } finally {
      dispatch(stopLoading());
    }
  };
};
