import React, { useEffect, useState } from "react";
import "./CreatePost.css";
import TitlePath from "../../../../components/TitlePath/TitlePath";
import DashboardHeading from "../../../../components/DashboardHeading/DashboardHeading";
import { useNavigate, useParams } from "react-router-dom";
import DashboardSubNavbar from "../../../../components/DashboardSubNavbar/DashboardSubNavbar";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import ImageUploadGallery from "../../../../components/ImageUploadGallery/ImageUploadGallery";
import VideoUploadGallery from "../../../../components/ImageUploadGallery/VideoUploadGallery";
import { createLearningHubRequest, fetchLearningHubById, updateLearningHubPost } from "../../../../services/LearningHub/LearningHub";
import { useDispatch, useSelector } from "react-redux";

const CreatePost = () => {
  const navigate = useNavigate();
  const [author, setAuthor] = useState("");
  const [publishedAt, setDate] = useState("");
  const [category, setCategory] = useState("Article");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [tags, setTags] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [activeHeading, setActiveHeading] = useState("Article");
  const [formErrors, setFormErrors] = useState({});
  const [removeImages, setRemoveImages] = useState([]); // Define state to track removed images

  const dispatch = useDispatch();
  //get id from params
  const id = useParams().id;
  const data = useSelector((state) => state.learninghub?.learningData);
  console.log(id, data);

  // when id trigger this useEffect
  useEffect(() => {
    if (id) {
      dispatch(fetchLearningHubById(id));
      console.log(id, data);
    }
  }, [id, dispatch]);
  useEffect(() => {
    if (data) {
      setAuthor(data?.author);
      //like set all data
      setDate(data?.publishedAt ? new Date(data.publishedAt).toISOString().split("T")[0] : "");
      setCategory(data?.category || "Article");
      setTitle(data?.title || "");
      setContent(data?.content || "");
      setTags(data?.tags || []);
      setImages(data?.images || []);
      setVideos(data?.videos || []);
      setActiveHeading(data?.category === "Webinar" ? "Webinar" : "Article");
    }
  }, [data]);

  const validateForm = () => {
    const errors = {};
    if (!author) errors.author = "Author is required.";
    if (!publishedAt) errors.publishedAt = "Date is required.";
    if (!title) errors.title = "Title is required.";
    if (!content) errors.content = "Content is required.";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append("author", author);
    formData.append("publishedAt", publishedAt);
    formData.append("category", activeHeading.toLowerCase());
    formData.append("title", title);
    formData.append("content", content);
    formData.append("status", "published");
    formData.append("tags", JSON.stringify(tags));
    videos.forEach((video) => formData.append("videos", video));
     // Append new images
      images.forEach((image) => {
        if (image.file) {
          formData.append("images", image.file);
        }
      });
     // Append removed image IDs
        if (removeImages.length > 0) {
          formData.append("removeImages", JSON.stringify(removeImages));
        }
  
    try {
      // Dispatch the Redux action instead of calling it directly
      const response = await dispatch(
        id
          ? updateLearningHubPost(id, formData)
          : createLearningHubRequest(formData)
      );
      console.log("Response from API:", response);

      // Fix navigation issue
        if (response?.status === "success") {  // Check correct response format
          navigate("/admin_dashboard/learning_hub");  
        } else {
          console.error("Error creating post:", response?.error || "Unknown error");
        }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleContentChange = (event) => setContent(event.target.value);

  const handleBack = () => navigate(`/admin_dashboard/learning_hub`);

  const titlePath = {
    exactPath: (
      <span style={{ cursor: "pointer" }} onClick={handleBack}>
        Learning_Hub
      </span>
    ),
    paths: [{ label: "Dashboard >", onClick: handleBack }],
  };

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath
           exactPath={titlePath.exactPath} paths={titlePath.paths}
        />
        <DashboardHeading
          heading="Learning Hub"
          des="Oversee content and user progress to ensure a seamless and effective learning experience."
        />
      </div>

      <div className="setting-page-container">
        <form className="update-post-form" onSubmit={handleSubmit}>
          <h4 style={{ fontWeight: 400 }}>Create Post</h4>
          <div className="update-post-form-first-child">
            <div className="form-group">
              <label htmlFor="author">Author</label>
              <input
                type="text"
                placeholder="Enter Author name here"
                id="author"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
              {formErrors.author && (
                <div className="post-error-message">{formErrors.author}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="date">Date</label>
              <input
                type="date"
                id="date"
                value={publishedAt}
                onChange={(e) => setDate(e.target.value)}
              />
              {formErrors.publishedAt && (
                <div className="post-error-message">
                  {formErrors.publishedAt}
                </div>
              )}
            </div>
          </div>

          <div className="form-group">
            <label>Category</label>
            <DashboardSubNavbar
              subNav={[{ navHeading: "Article" }, { navHeading: "Webinar" }]}
              setNavClick={setCategory}
              setHeading={setActiveHeading}
              activeHeading={activeHeading}
              setActiveHeading={setActiveHeading}
            />
          </div>

          <div className="form-group">
            <label htmlFor="title">Discussion Title</label>
            <input
              type="text"
              id="title"
              placeholder="Enter your discussion title here"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            {formErrors.title && (
              <div className="post-error-message">{formErrors.title}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="content">Discussion Content</label>
            <textarea
              id="content"
              placeholder="Enter your discussion here"
              value={content}
              onChange={handleContentChange}
              maxLength={10000}
              rows={12}
            />
            <div className="text-area-content-length-container">
              {content.length}/10000
            </div>
            {formErrors.content && (
              <div className="post-error-message">{formErrors.content}</div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="tags">Tags</label>
            <input
              type="text"
              id="tags"
              placeholder="Add Tag"
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  setTags([...tags, e.target.value]);
                  e.target.value = "";
                }
              }}
            />
            <div className="tags-container">
              {tags.map((tag, index) => (
                <span key={index} className="tag">
                  #{tag}
                  <button
                    type="button"
                    onClick={() => setTags(tags.filter((_, i) => i !== index))}
                  >
                    ×
                  </button>
                </span>
              ))}
            </div>
          </div>

          {activeHeading === "Article" ? (
            <ImageUploadGallery setImages={setImages} images={images} setRemoveImages={setRemoveImages} />
          ) : (
            <VideoUploadGallery setVideos={setVideos} videos={videos} />
          )}

          <div className="form-actions">
            <CustomButton
              label="Publish"
              type="submit"
              onClick={handleSubmit}
            />
            <CustomButton
              label="Save as Draft"
              onClick={() => console.log("Save as Draft clicked!")}
              type="dashboard-gray-button"
            />
            <CustomButton
              label="Cancel"
              onClick={handleBack}
              type="dashboard-gray-button"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePost;
