import React, { useEffect, useState } from "react";
import "./UserProfile.css";
import { useNavigate, useParams } from "react-router-dom";
import TitlePath from "../../../components/TitlePath/TitlePath";
import arrowBack from "../../../assets/images/adminLayout/Dashboard/Icons/arrowBack.svg";
import chatIcon from "../../../assets/images/adminLayout/Dashboard/Icons/chatIcon.svg";
import UserIcon from "../../../assets/images/adminLayout/Dashboard/Icons/user-icon-01.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserById, updateVerificationStatus } from "../../../services/admin/adminService";

function UserProfile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = useParams();
  // const [userId] = useState("67425570e0b92b646a6cf116"); // Example user ID (replace with dynamic ID if needed)
  const userId = params.id;
  const { user, loading } = useSelector((state) => state.admin); // Assuming adminReducer manages `user`
  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };


  useEffect(() => {
      if (id && selectedStatus) {
        dispatch(
          // updateVerificationStatus(id, { status: selectedStatus })
          updateVerificationStatus(id, selectedStatus) // Pass only the status
        );
      }
    }, [id, selectedStatus, dispatch]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    dispatch(fetchUserById(userId));
  }, [dispatch, userId]);

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };

  const handleBackTable = () => {
    navigate(`/admin_dashboard/user_management`);
  };

  const titlePath = {
    exactPath: (
      <span style={{ cursor: "pointer" }} onClick={handleBackTable}>
        user_management
      </span>
    ),
    paths: [{ label: "Dashboard > ", onClick: handleBack }],
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="main-dashboard-container">
      <div className="title-place">
        <TitlePath exactPath={titlePath.exactPath} paths={titlePath.paths} />
      </div>
      <div className="user-layouts-02">
        <div className="jra-header">
          <div className="jra-header-title">
            <img src={arrowBack} className="jra-header-title-icon" alt="Back" />
            <h4>
              User Management -{" "}
              <span className="green-heading">
                {user?.personalInfo?.firstName} {user?.personalInfo?.lastName} -{" "}
                {user?.index}
              </span>
            </h4>
          </div>
          <div className="jra-header-actions">
          <select
              value={selectedStatus}
              onChange={handleStatusChange}
              className="status-select"
            >
              <option value="" disabled className="status-option">
                Mark Status
              </option>
              <option value="approved" className="status-option completed">
                Mark it as approved
              </option>
              <option value="rejected" className="status-option rejected">
                Mark it as Rejected
              </option>
              <option value="pending" className="status-option pending">
                Mark it as Pending
              </option>
              {/* <option value="inProgress" className="status-option pending">
                Mark it as In-Progress
              </option> */}
            </select>
            {/* <button className="jra-chat-button">
              <img src={chatIcon} className="chatIcon" alt="Chat" />
              Chat 
              <span className="jra-notification-badge">15</span>
            </button> */}
          </div>
        </div>
        <div className="user-full-detailes">
          <h5 className="user-information-heading">Application Status</h5>
          <div className="jra-status-row">
            <div className="user-image-place">
              <img className="userImage-class" src={UserIcon} alt="User" />

              {/* <div>
                                {imageLoaded ? (
                                <img className="userImage-class" src={user?.profileImageUrl || UserIcon} alt="User" onLoad={handleImageLoad} />
                                ) : (
                                <div>Loading Image...</div>
                                )}
                            </div> */}
              <div>
                <p className="jra-status-label">Name</p>
                <p className="jra-status-value">
                  {user?.personalInfo?.firstName} {user?.personalInfo?.lastName}
                </p>
              </div>
            </div>
            <div className="sub-date">
              <p className="jra-status-label">Submission Date</p>
              <p className="jra-status-value">
                {new Date(user?.createdAt).toLocaleDateString()}
              </p>
            </div>
          </div>

          <h5 className="user-information-heading">User Information</h5>
          <div className="jra-grid-2-col">
            <div>
              <p className="jra-status-label">Date Of Birth</p>
              <p className="jra-status-value">
                {new Date(user?.personalInfo?.dateOfBirth).toLocaleDateString()}
              </p>
            </div>
            <div>
              <p className="jra-status-label">E-Mail Address</p>
              <p className="jra-status-value">{user?.email}</p>
            </div>
            <div>
              <p className="jra-status-label">Phone Number</p>
              <p className="jra-status-value">{user?.mobile}</p>
            </div>
            <div>
              <p className="jra-status-label">Role</p>
              <p className="jra-status-value">{user?.userRole}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
