// utils/apiRequest.js
import { showToastMessage } from "../redux/actions/toastActions";

export const apiRequest = async (url, options = {}, json, dispatch) => {
  const authToken = localStorage.getItem("authToken");

  const config = {
    ...options,
    headers: {
      ...(json && { "Content-Type": "application/json"}), // Add "Content-Type" header only if json is truthy
      Authorization: `Bearer ${authToken}`, // Add Authorization header
      ...options, // Include additional headers if provided in options
    },
  };

  try {
    console.log("Requesting:", url, config);

    // Actual fetch call
    const response = await fetch(url, config);

    console.log("Response status:", response);

    if (response.status === 401) {
      // Handle unauthorized access
      if (dispatch) {
        dispatch(
          showToastMessage("Session expired. Redirecting to login.", "error"),
        );
      }
      window.location.href = "/login"; // Redirect to the login page
      return;
    }
    if (response.status === 204) {
      // Handle unauthorized access
      if (dispatch) {
        dispatch(showToastMessage("Successfully deleted", "success"));
      }
      return;
    }

    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(
        errorMessage || "An error occurred while processing the request.",
      );
    }

    return await response.json();
  } catch (error) {
    console.error("API Request Error:", error.message);
    if (dispatch) {
      dispatch(showToastMessage(error.message, "error"));
    }
    throw error; // Pass the error back for the calling function to handle
  }
};
