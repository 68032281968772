import React, { useEffect, useState } from "react";
import "./ServiceManagemantTable.css";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../../components/TitlePath/TitlePath";
import DashboardTable from "../../../../components/DashboardTable/DashboardTable";
import DashboardHeading from "../../../../components/DashboardHeading/DashboardHeading";
import { fetchAdminJobReferralsAsyn } from "../../../../services/jobSeeker/jobSeekerService";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const JobReferralsTable = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("All");
  const location = useLocation();

  const dispatch = useDispatch();

  const jobReferrals = useSelector(
    (state) => state.jobSeeker.adminJobReferrals
  );

  console.log("job referral : ", jobReferrals);

  useEffect(() => {
    dispatch(fetchAdminJobReferralsAsyn());
  }, [dispatch]);

  const columns = [
    { key: "index", label: "ID", isClickable: true },
    { key: "userName", label: "User Name", isClickable: true },
    { key: "jobTitle", label: "Job Title", isClickable: true },
    { key: "company", label: "Company", isClickable: true },
    { key: "submissionDate", label: "Submission Date", isClickable: true },
    {
      key: "status",
      label: "Status",
      type: "status",
      sortable: true,
      isClickable: true,
    }
  ];

  // Calculate counts for each tab
  const allCount = jobReferrals.length;
  const approvedCount = jobReferrals.filter(
    (jobReferral) => jobReferral.status === "approved"
  ).length;
  const pendingCount = jobReferrals.filter(
    (jobReferral) => jobReferral.status === "pending"
  ).length;
  const rejectedCount = jobReferrals.filter(
    (jobReferral) => jobReferral.status === "rejected"
  ).length;

  // Filter admins based on active tab
  const filteredData =
    activeTab === "All"
      ? jobReferrals
      : jobReferrals.filter(
          (jobReferral) => jobReferral.status === activeTab.toLowerCase()
        );

  const handleViewClick = (id) => {
    console.log("id", id);
    navigate(`/admin_dashboard/job_referrals/${id}`);
  };

  const tabs = [
    { name: "All", count: allCount },
    { name: "Approved", count: approvedCount },
    { name: "Pending", count: pendingCount },
    { name: "Rejected", count: rejectedCount },
  ];

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };
  const titlePath = {
    exactPath: (
      <span style={{ cursor: "pointer" }} onClick={handleBack}>
        service_management
      </span>
    ),
    paths: [
      { label: "Dashboard > ", onClick: handleBack },
    ],
  };
  const headingDes = {
    heading: "Service Management",
    des: "Streamline and oversee all services to ensure seamless operations and user satisfaction.",
  };
  const subNav = [
    { navHeading: "Job Referrals" },
    // { navHeading: "Career Advice" },
    { navHeading: "Resume Review" },
  ];

  const [navClick, setNavClick] = useState("Job Referrals");
  const [heading, setHeading] = useState("Job Referrals");
  const [activeHeading, setActiveHeading] = useState("Job Referrals");

  // Update your useEffect to track the current path
    useEffect(() => {
      const currentPath = location.pathname;
      
      // Match pathnames to update the activeHeading
      if (currentPath.includes("service_management")) {
        setActiveHeading("Job Referrals");
      } else if (currentPath.includes("career_advice_table")) {
        setActiveHeading("Career Advice");
      } else if (currentPath.includes("resume_review")) {
        setActiveHeading("Resume Review");
      } else {
        setActiveHeading("Job Referrals"); // Default or fallback
      }
    }, [location.pathname]);

  const handleClick = (heading) => {
    setActiveHeading(heading);
    setHeading(heading);
  
    if (heading === "Job Referrals") {
      navigate("/admin_dashboard/service_management");
    } else if (heading === "Career Advice") {
      navigate("/admin_dashboard/career_advice_table");
    } else if (heading === "Resume Review") {
      navigate("/admin_dashboard/resume_review");
    }
  };
  

  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath
          exactPath={titlePath.exactPath}
          paths={titlePath.paths}
          showNotification={true}
        />
        <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
      </div>
      <div className="setting-page-container">
        <div className="dashboard-subnav-button-container">
          {subNav.map((data) => (
            <div
              key={data.navHeading}
              onClick={() => handleClick(data.navHeading)}
              className={`subnav-button-item ${
                activeHeading === data.navHeading ? "active" : ""
              }`}
            >
              <div>{data.navHeading}</div>
            </div>
          ))}
        </div>

        <div className="sub-setting-page-container blog-post-table-container">
          <DashboardTable
            tableData={filteredData}
            tableHeading={columns}
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            handleViewClick={handleViewClick}
          />
        </div>
      </div>
    </div>
  );
};

export default JobReferralsTable;
