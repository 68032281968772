import React, { useState, useEffect} from "react";
import "./DiscussionForm.css";
import { useNavigate } from "react-router-dom";
import TitlePath from "../../../components/TitlePath/TitlePath";
import DashboardTable from "../../../components/DashboardTable/DashboardTable";
import DashboardHeading from "../../../components/DashboardHeading/DashboardHeading";
import CustomTable from "../../../components/CustomTable/CustomTable";
import { fetchDiscussions } from "../../../services/DiscussionForum/DiscussionForum";
import { useSelector, useDispatch } from "react-redux";

const DiscussionForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux state
  const {  discussions, loading, error } = useSelector((state) => state.discussionForum);


  // Fetch discussions on component mount
       useEffect(() => {
        dispatch(
          fetchDiscussions({
            page: 1,
            limit: 10,
            search: "What are the best practices",
            isDashboard: true,
            status: "published",
            tag: "API",
            type: "recent",
            isRecentlyVisited: true,
          })
        );
      }, [dispatch]);

  const [activeTab, setActiveTab] = useState("All");
  

  // const handleViewClick = (rowData) => {
  //   console.log("Row clicked:", rowData);
  // };

  const tabs = [
    { name: "All", count: 84 },
    { name: "Approved", count: 39 },
    { name: "Pending", count: 33 },
  ];

  const columns = [
    { key: "index", label: "Post ID" , isClickable: true },
    { key: "title", label: "Title" , isClickable: true },
    { key: "author", label: "Author" , isClickable: true },
    { key: "publishedAt", label: "Date Created" , isClickable: true  },
    { key: "status", label: "Status", type: "status", sortable: true , isClickable: true  },
  ];


  const mappedData = Array.isArray(discussions)
  ? discussions.map(item => ({
      id: item._id, // Ensure this exists
      index: item.index || "N/A", // Default value if not found
      title: item.title || "Untitled",
      category: item.category
        ? item.category.charAt(0).toUpperCase() + item.category.slice(1)
        : "Uncategorized",
      author: item.user
        ? `${item.user.firstName} ${item.user.lastName}`
        : "Unknown",
        publishedAt: item.publishedAt
        ? new Date(item.publishedAt).toLocaleDateString()
        : "N/A",
      status: item.status
        ? item.status.charAt(0).toUpperCase() + item.status.slice(1)
        : "Unknown",
    }))
  : [];


  const handleViewClick = (id) => {
    navigate(`/admin_dashboard/discussion_forum_Details/${id}`);
    
    
  };


  const data = [
    {
      PostID: "CD001",
      title: "Mastering Job Interviews: Tips from Top Re...",
      author: "Mitchell Johnson",
      DateCreated: "12 Mar 2022",
      status: "Published",
    },
    {
      PostID: "CD002",
      title: "Building a Strong Professional Network Am...",
      author: "Brett Lee",
      DateCreated: "08 Apr 2022",
      status: "Restricted",
    },
    {
      PostID: "CD003",
      title: "5 Ways to Enhance Your LinkedIn Profile to...",
      author: "Ricky Ponting",
      DateCreated: "23 Mar 2022",
      status: "Restricted",
    },
    {
      PostID: "CD004",
      title: "Managing Work-Life Balance in a Busy Worl...",
      author: "Mitchell Johnson",
      DateCreated: "09 May 2023",
      status: "Published",
    },
    {
      PostID: "CD005",
      title: "LinkedIn Profile Mastery",
      author: "Glenn Maxwell",
      DateCreated: "30 Aug 2023",
      status: "Restricted",
    },
  ];

  const handleBack = () => {
    navigate(`/admin_dashboard`);
  };
  const titlePath = {
    exactPath: (
      <span style={{ cursor: "pointer" }} onClick={handleBack}>
        discussion_forum
      </span>
    ),
    paths: [
      { label: "Dashboard > ", onClick: handleBack },
    ],
  };
  const headingDes = {
    heading: "Discussion Forum",
    des: "Efficiently manage and moderate discussions to maintain a safe and engaging forum environment.",
  };
  return (
    <div className="main-dashboard-container">
      <div>
        <TitlePath
          exactPath={titlePath.exactPath}
          paths={titlePath.paths}
          showNotification={true}
        />
        <DashboardHeading heading={headingDes.heading} des={headingDes.des} />
      </div>
      <div className="setting-page-container">
        <div className="sub-setting-page-container blog-post-table-container">
          <div className="dashboard-table-heading">
            <h4>Overview</h4>
          </div>

          {loading ? (
            <p>Loading discussions...</p>
          ) : error ? (
            <p>Error loading discussions: {error}</p>
          ) : (
          <CustomTable
            columns={columns}
            data={mappedData}
            tabs={tabs}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            handleViewClick={handleViewClick} // Add this line
          />
        )}
        </div>
      </div>
    </div>
  );
};

export default DiscussionForm;
